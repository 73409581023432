import { ReactComponent as PhoneOtpIconIcon } from "./phone-otp.svg";

const PhoneOtpIcon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
        title?: string | undefined;
    }
> = (props) => <PhoneOtpIconIcon {...props} />;

export default PhoneOtpIcon;

