import { useEffect, useState } from 'react';
import { Box, Flex } from 'rebass';
import { MobileCardDetails, TransferDetails } from '../style';
import { OtpInput, Typography } from 'kudi-component-library';
import { checkoutOtp, resendCardOtp } from '../../../redux/action';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store';
import Loader from '../../Loader';
import { ScreenTypes } from '../../../utils';
import { CountdownTimer } from '../../../utils/Countdown';
import PhoneOtpIcon from '../../../assets/phone-otp';
import Select from '../../Select';

export default function CardOtp({
  saveCard,
  transactionId,
  otpMessage,
  orderReference,
  onMoveToNewScreen,
  setCurrentSubScreen,
  setActive,
}: {
  saveCard: boolean;
  onMoveToNewScreen: (newscreen: ScreenTypes, error: string, saveCard: boolean) => void;
  otpMessage: string;
  setCurrentSubScreen: React.Dispatch<React.SetStateAction<ScreenTypes>>;
  orderReference: string;
  transactionId: string;
  setActive: React.Dispatch<React.SetStateAction<number>>;
}) {
  const [otpValue, setOtpValue] = useState<string>('');
  const [otpError, setOtpError] = useState<string>('');
  const [otpMsg, setOtpMsg] = useState<string>(otpMessage);
  const [inProgress, setInprogress] = useState<boolean>(false);
  const [timer, setTimer] = useState<number>(1);
  const [selectValue, setSelectValue] = useState('');

  const dispatch = useDispatch<AppDispatch>();
  const { otpResponse } = useSelector((state: RootState) => state.order);

  function handleChange(val: string) {
    setOtpValue(val);
    setOtpError('');
    if (val.length === 6) {
      handleSubmit(val);
    }
  }

  const handleSubmit = (val: string) => {
    setInprogress(true);
    const payload = {
      otp: val,
      orderReference: orderReference,
      transactionId: transactionId,
    };
    orderReference && dispatch(checkoutOtp(payload));
  };

  const handleInputChange = (value: string, name: string) => {
    setSelectValue(value);

    if (value === '1') {
      setCurrentSubScreen('TransferDetails');
    }

    if (value === '2') {
      setCurrentSubScreen('CardInput');
    }

    if (value === '3') {
      setCurrentSubScreen('NombaQR');
    }

    if (value === '4') {
      setCurrentSubScreen('USSD');
    }

    setActive(Number(value));
  };

  useEffect(() => {
    if (otpResponse === null) {
      return;
    }
    if (otpResponse?.data?.status) {
      setInprogress(false);
      setOtpError('');
      onMoveToNewScreen('SuccessResponse', '', saveCard);
    } else {
      setInprogress(false);
      setOtpError(otpResponse?.data?.message || 'An error occured, please try again');
    }
  }, [otpResponse, saveCard, onMoveToNewScreen]);

  const resendOtp = async () => {
    setInprogress(true);
    const payload = {
      orderReference: orderReference,
    };
    if (orderReference) {
      const result = await dispatch(resendCardOtp(payload));
      setInprogress(false);
      setTimer(1);
      setOtpMsg(result?.payload?.data?.message || 'An error occured, please try again');
    }
  };

  const paymentMethods = [
    { text: 'Try another card', value: '2' },
    { text: 'Try pay with Transfer', value: '1' },
    // { text: 'Try pay with Bank', value: '1' },
    // { text: 'Try pay with USSD', value: '4' },
    { text: 'Try pay with Nomba QR', value: '3' },
    { text: 'Try again with the same card', value: '2' },
  ];

  return (
    <>
      <TransferDetails style={{ marginTop: '30px', textAlign: 'center' }}>
        {inProgress ? (
          <Flex flexDirection="column" style={{ gap: '10px' }}>
            <Loader />
            <Typography color="n-grey4" fontWeight={500}>
              Please wait
            </Typography>
          </Flex>
        ) : (
          <>
            <Typography color="n-dark" fontWeight={500} lineHeight="18px">
              {otpMsg}
            </Typography>
            <Box mt="40px">
              <OtpInput
                width="100%"
                inputsNumber={6}
                name="token"
                value={otpValue}
                onChange={(e) => handleChange(e.target.value)}
                error={otpError}
              />
            </Box>

            {timer > 0 ? (
              <Flex mt="30px" alignItems="center" style={{ gap: '5px' }}>
                <Typography color="dark" fontWeight={500}>
                  Resend OTP in
                </Typography>
                <CountdownTimer
                  {...{ minutes: timer, seconds: 0, cb: () => setTimer(0), color: 'dark', fontSize: '14px' }}
                />
                <Typography color="dark" fontWeight={500}>
                  secs
                </Typography>
              </Flex>
            ) : (
              <>
                <Box mt="30px">
                  <Typography onClick={() => resendOtp()} className="cursor-pointer" color="n-yellow8" fontWeight={500}>
                    Resend OTP
                  </Typography>
                </Box>

                <Box mt="30px">
                  <Select
                    label=""
                    placeholder="Change payment method"
                    name="changePaymentMethod"
                    options={paymentMethods}
                    value={selectValue}
                    onChange={(e) => {
                      handleInputChange(e.target.value ?? '', 'changePaymentMethod');
                    }}
                    width="250px"
                  />
                </Box>
              </>
            )}
          </>
        )}
      </TransferDetails>

      <MobileCardDetails style={{ height: '50vh', alignItems: 'center', textAlign: 'center' }}>
        {inProgress ? (
          <Flex flexDirection="column" style={{ gap: '10px' }}>
            <Loader />
            <Typography color="n-grey4" fontWeight={500}>
              Please wait
            </Typography>
          </Flex>
        ) : (
          <>
            <PhoneOtpIcon />

            <Typography color="n-dark" fontWeight={500} lineHeight="18px" style={{ marginTop: '30px' }}>
              {otpMsg}
            </Typography>

            <Box mt="40px">
              <OtpInput
                width="100%"
                inputsNumber={6}
                name="token"
                value={otpValue}
                onChange={(e) => handleChange(e.target.value)}
                error={otpError}
              />
            </Box>
            {timer > 0 ? (
              <Flex mt="30px" alignItems="center" style={{ gap: '5px' }}>
                <Typography color="dark" fontWeight={500}>
                  Resend OTP in
                </Typography>
                <CountdownTimer
                  {...{ minutes: timer, seconds: 0, cb: () => setTimer(0), color: 'dark', fontSize: '14px' }}
                />
                <Typography color="dark" fontWeight={500}>
                  secs
                </Typography>
              </Flex>
            ) : (
              <>
                <Box mt="30px">
                  <Typography onClick={() => resendOtp()} className="cursor-pointer" color="n-yellow8" fontWeight={500}>
                    Resend OTP
                  </Typography>
                </Box>

                <Box mt="30px">
                  <Select
                    label=""
                    placeholder="Change payment method"
                    name="changePaymentMethod"
                    options={paymentMethods}
                    value={selectValue}
                    onChange={(e) => {
                      handleInputChange(e.target.value ?? '', 'changePaymentMethod');
                    }}
                    width="100%"
                  />
                </Box>
              </>
            )}
          </>
        )}
      </MobileCardDetails>
    </>
  );
}
