import { ReactComponent as InfoImg } from "./info.svg";

const InfoIcon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
        title?: string | undefined;
    }
> = (props) => <InfoImg {...props} />;

export default InfoIcon;

