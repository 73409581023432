import { ReactComponent as MasterCardIcon } from "./mastercard.svg";

const MasterCard: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
        title?: string | undefined;
    }
> = (props) => <MasterCardIcon {...props} />;

export default MasterCard;

