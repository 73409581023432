import { useEffect } from "react"
import { NTheme } from "kudi-component-library"
import { Flex } from "rebass"
import { ThemeProvider } from "styled-components"

export default function SecureAuth({ params }: { params: { jwt: string, md: string, acsUrl: string } }) {
    useEffect(() => {
        const form = document.getElementById('form1') as HTMLFormElement;
        if (form) {
            form.submit();
        }
    }, []);

    return (
        <ThemeProvider theme={NTheme}>
            <Flex justifyContent={"center"} alignItems={"center"} height={"100vh"}>
                <form id="form1" action={params.acsUrl} method="post">
                    <input type="hidden" name="JWT" value={params.jwt} />
                    <input type="hidden" name="MD" value={params.md} />
                </form>
            </Flex>
        </ThemeProvider>
    )
}
