import { ReactComponent as HelpIconIcon } from "./help.svg";

const HelpIcon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
        title?: string | undefined;
    }
> = (props) => <HelpIconIcon {...props} />;

export default HelpIcon;

