import { Suspense, useState } from "react"
import { NTheme } from "kudi-component-library"
import { ThemeProvider } from "styled-components"
import { AppBody } from "../styles"
import Loader from "../Loader"
import SandboxCheckoutModal from "./SandboxCheckoutModal"

export default function Sandbox() {
    const [showModal] = useState<boolean>(true);

    return !showModal ? <Loader /> : (
        <ThemeProvider theme={NTheme}>
            <AppBody>
                <Suspense fallback={<h1>Loading...</h1>}>
                    {showModal && <SandboxCheckoutModal showModal={showModal} />}
                </Suspense>
            </AppBody>
        </ThemeProvider>
    )
}

