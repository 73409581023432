import { ReactComponent as VisaIcon } from "./visa.svg";

const VisaCard: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
        title?: string | undefined;
    }
> = (props) => <VisaIcon {...props} />;

export default VisaCard;

