import { createAsyncThunk } from '@reduxjs/toolkit';
import { handleApiCall } from '../httpConfig';

export interface CheckoutRequest {
  orderReference: string;
  key: string;
  cardDetails: string;
  saveCard?: boolean;
  deviceInformation: {
    httpBrowserLanguage: string;
    httpBrowserJavaEnabled: string;
    httpBrowserJavaScriptEnabled: string;
    httpBrowserColorDepth: string;
    httpBrowserScreenHeight: string;
    httpBrowserScreenWidth: string;
    httpBrowserTimeDifference: string;
    userAgentBrowserValue: string;
    deviceChannel: string;
  };
}

export interface OtpRequest {
  otp: string;
  orderReference: string | undefined;
  transactionId?: string | undefined;
}

export interface ConfirmReceiptRequest {
  orderReference: string;
  transactionType?: string;
}
export interface GetSavedCardsRequest {
  orderReference: string;
  phoneNumber?: string;
  otp?: string;
}
export interface PayWithSavedCardRequest {
  orderReference: string;
  tokenKey: string;
}

export const getOrderDetails = createAsyncThunk(
  'checkout/order',
  async (payload: { orderId: string }, { rejectWithValue }) => {
    try {
      const response = await handleApiCall(`/checkout/order/${payload.orderId}`, 'GET');
      return response.data;
    } catch (error: any) {
      return rejectWithValue({ error });
    }
  }
);

export const getSandboxOrderDetails = createAsyncThunk(
  'sandbox/checkout/order',
  async (payload: { orderId: string }, { rejectWithValue }) => {
    try {
      const response = await handleApiCall(`/sandbox/checkout/order/${payload.orderId}`, 'GET');
      return response.data;
    } catch (error: any) {
      return rejectWithValue({ error });
    }
  }
);

export const createOrder = createAsyncThunk(
  'checkout/checkout-card-detail',
  async (payload: CheckoutRequest, { rejectWithValue }) => {
    try {
      const response = await handleApiCall('/checkout/checkout-card-detail', 'POST', payload);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({ error });
    }
  }
);
export const createSandboxOrder = createAsyncThunk(
  'sandbox/checkout/checkout-card-detail',
  async (payload: CheckoutRequest, { rejectWithValue }) => {
    try {
      const response = await handleApiCall('/sandbox/checkout/checkout-card-detail', 'POST', payload);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({ error });
    }
  }
);

export const checkoutOtp = createAsyncThunk(
  'checkout/checkout-card-otp',
  async (payload: OtpRequest, { rejectWithValue }) => {
    try {
      const response = await handleApiCall('/checkout/checkout-card-otp', 'POST', payload);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({ error });
    }
  }
);

export const checkoutSandboxOtp = createAsyncThunk(
  'sandbox/checkout/checkout-card-otp',
  async (payload: OtpRequest, { rejectWithValue }) => {
    try {
      const response = await handleApiCall('/sandbox/checkout/checkout-card-otp', 'POST', payload);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({ error });
    }
  }
);

export const getKtaDetails = createAsyncThunk(
  'checkout/get-checkout-kta',
  async (payload: { orderId: string }, { rejectWithValue }) => {
    try {
      const response = await handleApiCall(`/checkout/get-checkout-kta/${payload.orderId}`, 'GET');
      return response.data;
    } catch (error: any) {
      return rejectWithValue({ error });
    }
  }
);

export interface ConfirmReceiptRequest {
  orderReference: string;
}
export interface GetSavedCardsRequest {
  orderReference: string;
  phoneNumber?: string;
  otp?: string;
}
export interface PayWithSavedCardRequest {
  orderReference: string;
  tokenKey: string;
}
export interface CreateCheckoutData {
  amount?: string;
  currency?: string;
  customerEmail?: string;
  customerFirstName?: string;
  customerLastName?: string;
  paymentReference?: string;
  frequency?: string;
  numberOfPayments?: string;
  tippingAmount?: string;
}

export const confirmBankTransfer = createAsyncThunk(
  'checkout/confirm-transaction-receipt',
  async (payload: ConfirmReceiptRequest, { rejectWithValue }) => {
    try {
      const response = await handleApiCall(`/checkout/confirm-transaction-receipt`, 'POST', payload);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({ error });
    }
  }
);

export const resendCardOtp = createAsyncThunk(
  'checkout/resend-otp',
  async (payload: ConfirmReceiptRequest, { rejectWithValue }) => {
    try {
      const response = await handleApiCall(`/checkout/resend-otp`, 'POST', payload);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({ error });
    }
  }
);

export const fetchSavedCards = createAsyncThunk(
  'checkout/user-card',
  async (payload: GetSavedCardsRequest, { rejectWithValue }) => {
    try {
      const response = await handleApiCall(`/checkout/user-card/${payload.orderReference}?otp=${payload.otp}`, 'GET');
      return response.data;
    } catch (error: any) {
      return rejectWithValue({ error });
    }
  }
);

export const authenticatePhone = createAsyncThunk(
  'checkout/user-card/auth',
  async (payload: GetSavedCardsRequest, { rejectWithValue }) => {
    try {
      const response = await handleApiCall(`/checkout/user-card/auth`, 'POST', payload);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({ error });
    }
  }
);

export const verifyPhone = createAsyncThunk(
  '/checkout/user-card/saved-card/auth',
  async (payload: GetSavedCardsRequest, { rejectWithValue }) => {
    try {
      const response = await handleApiCall(`/checkout/user-card/saved-card/auth`, 'POST', payload);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({ error });
    }
  }
);

export const saveUserCard = createAsyncThunk(
  'checkout/user-card',
  async (payload: GetSavedCardsRequest, { rejectWithValue }) => {
    try {
      const response = await handleApiCall('/checkout/user-card', 'POST', payload);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({ error });
    }
  }
);

export const deleteSavedCard = createAsyncThunk('checkout/user-card', async (tokenKey: string, { rejectWithValue }) => {
  try {
    const response = await handleApiCall('/checkout/user-card', 'DELETE', {
      tokenKey,
    });
    return response.data;
  } catch (error: any) {
    return rejectWithValue({ error });
  }
});
export const payWithCard = createAsyncThunk(
  'checkout/user-card/pay',
  async (payload: PayWithSavedCardRequest, { rejectWithValue }) => {
    try {
      const response = await handleApiCall('/checkout/user-card/pay', 'POST', payload);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({ error });
    }
  }
);
export const fetchPaymentLink = createAsyncThunk(
  'checkout/payment-links',
  async (payload: { orderId: string }, { rejectWithValue }) => {
    try {
      const response = await handleApiCall(`/checkout/payment-links/${payload.orderId}`, 'GET');
      return response.data;
    } catch (error: any) {
      return rejectWithValue({ error });
    }
  }
);
export const createCheckoutLink = createAsyncThunk(
  'checkout/payment-links/create-checkout-link',
  async (payload: CreateCheckoutData, { rejectWithValue }) => {
    try {
      const response = await handleApiCall('/checkout/payment-links', 'POST', payload);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({ error });
    }
  }
);

export const fetchSubscriptionDetails = createAsyncThunk(
  'checkout//payment-links/subscriptions',
  async (payload: { orderId: string }, { rejectWithValue }) => {
    try {
      const response = await handleApiCall(`/checkout/payment-links/subscriptions/${payload.orderId}`, 'GET');
      return response.data;
    } catch (error: any) {
      return rejectWithValue({ error });
    }
  }
);

export const cancelPaymentLinkSubscription = createAsyncThunk(
  '/checkout/payment-links/subscriptions',
  async (payload: string, { rejectWithValue }) => {
    try {
      const response = await handleApiCall(`/checkout/payment-links/subscriptions/${payload}`, 'PATCH');
      return response.data;
    } catch (error: any) {
      return rejectWithValue({ error });
    }
  }
);

export const fetchQrCode = createAsyncThunk(
  'checkout/qr',
  async (payload: { orderId: string }, { rejectWithValue }) => {
    try {
      const response = await handleApiCall(`/checkout/qr/${payload.orderId}`, 'GET');
      return response.data;
    } catch (error: any) {
      return rejectWithValue({ error });
    }
  }
);

// Fetch Bank --- START
export const fetchBanks = createAsyncThunk('checkout/fetchBanks', async (_, { rejectWithValue }) => {
  try {
    const response = await handleApiCall(`/checkout/ussd/banks`, 'GET');

    return response.data;
  } catch (error: any) {
    return rejectWithValue({ error });
  }
});
// END

export interface UssdCodeRequest {
  ussdBankId: string;
  bankName: string;
  bankCode: string;
  processor: string;
  processorCodeName: string;
  ussdCodeFormat: string;
  enabled: boolean;
  promptMessage: string;
}

// Send USSD Code --- START
export const sendUssdCode = createAsyncThunk(
  'checkout/sendUssdCode',
  async (
    payload: {
      ussdCode: UssdCodeRequest;
      orderId: string | undefined;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await handleApiCall(`/checkout/ussd/code`, 'POST', payload);

      return response.data;
    } catch (error: any) {
      return rejectWithValue({ error });
    }
  }
);
// END
