import { Suspense, useState } from "react";
import CheckoutModal from "./CheckoutModal";

export default function CheckoutForm() {
    const [showModal] = useState<boolean>(true);

    return (
        <Suspense fallback={<h1>Loading...</h1>}>
            {showModal && <CheckoutModal showModal={showModal} />}
        </Suspense>
    );
}
