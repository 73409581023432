import { ReactComponent as InfoIconIcon } from "./info-icon.svg";

const InfoIcon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
        title?: string | undefined;
    }
> = (props) => <InfoIconIcon {...props} />;

export default InfoIcon;

