import { ReactComponent as VisaSavedIcon } from "./visa-saved.svg";

const VisaCardSaved: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
        title?: string | undefined;
    }
> = (props) => <VisaSavedIcon {...props} />;

export default VisaCardSaved;

