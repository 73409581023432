import { ReactComponent as NombaQrActiveIconIcon } from "./qr-active.svg";

const NombaQrActiveIcon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
        title?: string | undefined;
    }
> = (props) => <NombaQrActiveIconIcon {...props} />;

export default NombaQrActiveIcon;

