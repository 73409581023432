import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Box, Button, Flex } from "rebass"
import { formatCurrency, ScreenTypes } from "../utils"
import { AppHeader, AppHeaderText, ButtonText, InfoText, PaymentMethodBox, WhiteBox } from "./styles"
import { AppDispatch, RootState } from "../store";
import { getKtaDetails } from "../redux/action";
import { errorResponse, successResponse } from "../utils/toast";
import BankTransferWait from "./BankTransferWait";
import Loader from "./Loader";

export default function BankTransfer({ amount, onMoveToNewScreen }: { amount: number | undefined, onMoveToNewScreen: (newscreen: ScreenTypes) => void }) {
    const dispatch = useDispatch<AppDispatch>();
    const { isLoading, ktaResponse } = useSelector((state: RootState) => state.order);
    const { id } = useParams();
    const [showVerify, setShowVerify] = useState(false);

    useEffect(() => {
        id && dispatch(getKtaDetails({ orderId: id }))
    }, [dispatch, id])

    const handleFetchKta = async () => {
        id && dispatch(getKtaDetails({ orderId: id }))
    }

    const handleVerify = () => {
        setShowVerify(true);
    }

    const goHome = () => {
        onMoveToNewScreen("PaymentMethods");
    }

    const handleCopy = (val: string | number, copyType: string) => {
        navigator.clipboard.writeText(val.toString()).then(function () {
            successResponse(`${copyType} copied to clipboard`)
        }).catch(() =>
            errorResponse('Unable to copy')
        );
    }

    return (
        showVerify ? <BankTransferWait orderReference={id} amount={amount} bankDetails={ktaResponse} onMoveToNewScreen={onMoveToNewScreen} />
            :
            <Box className="flex">
                {isLoading ?
                    <Flex flexDirection="column" alignItems="center" justifyContent="center" pb="40px">
                        <Loader />
                    </Flex>
                    :
                    <Flex flexDirection="column" alignItems="flex-start">
                        <AppHeader>Bank transfer</AppHeader>
                        <InfoText fontSize="12px">Kindly use the account details below for your transfer.</InfoText>
                        <PaymentMethodBox>
                            <Box>
                                <InfoText fontSize="12px">Amount</InfoText>
                                <Flex justifyContent="space-between" alignItems="center">
                                    <AppHeaderText fontSize="16px" textAlign="left">{amount ? formatCurrency(amount) : "N/A"}</AppHeaderText>
                                    <WhiteBox onClick={() => amount && handleCopy(amount, "Amount")}>Copy</WhiteBox>
                                </Flex>
                            </Box>
                            <Box mt="10px">
                                <InfoText fontSize="12px">Account number</InfoText>
                                <Flex justifyContent="space-between" alignItems="center">
                                    <AppHeaderText fontSize="16px" textAlign="left">{ktaResponse?.data?.accountNumber || "N/A"}</AppHeaderText>
                                    <WhiteBox onClick={() => ktaResponse?.data?.accountNumber && handleCopy(ktaResponse?.data?.accountNumber, 'Account Number')}>Copy</WhiteBox>
                                </Flex>
                            </Box>
                            <Box mt="10px">
                                <InfoText fontSize="12px">Bank name</InfoText>
                                <AppHeaderText fontSize="16px" textAlign="left">{ktaResponse?.data?.bankName || "N/A"}</AppHeaderText>
                            </Box>
                            <Box mt="10px">
                                <InfoText fontSize="12px">Account name</InfoText>
                                <AppHeaderText fontSize="16px" textAlign="left">{ktaResponse?.data?.accountName || "N/A"}</AppHeaderText>
                            </Box>
                        </PaymentMethodBox>
                        {ktaResponse?.code === "00" ?
                            <Button textAlign="center" onClick={handleVerify} mb="10px" bg="#FFCC00" color="#121212" variant="large" width="100%" height="40px" className="cursor-pointer">
                                <ButtonText>I have transferred {amount && formatCurrency(amount)}</ButtonText>
                            </Button>
                            :
                            <Button textAlign="center" onClick={handleFetchKta} mb="10px" bg="#FFCC00" color="#121212" variant="large" width="100%" height="40px" className="cursor-pointer">
                                <ButtonText>Retry</ButtonText>
                            </Button>
                        }
                        <Button onClick={goHome} bg="#FFFFFF" color="#121212" variant="large" width="100%" height="40px" className="cursor-pointer change-btn">
                            <ButtonText>Change payment method</ButtonText>
                        </Button>
                    </Flex>
                }
            </Box>
    )
}
