import styled, { keyframes } from 'styled-components';

interface FormInputProps {
	isError?: boolean;
}

export const PaymentWrapper = styled.div`
	display: none;
	flex-direction: column;
	height: 100%;

	@media ${({ theme }) => theme.media.md} {
		display: flex;
		flex-direction: row;
		gap: 10px;
		height: 100%;
	}
`;
export const MobilePaymentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;

	@media ${({ theme }) => theme.media.md} {
		display: none;
		flex-direction: row;
		gap: 10px;
		height: 100%;
	}
`;

export const MobilePaymentMethods = styled.div`
	background: ${({ theme }) => theme.nColors['n-grey0']};
	border-radius: 24px;
	height: fit-content;
`;

export const MobilePaymentMethod = styled.div`
	padding: 30px;
	margin-top: 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
`;
export const LeftPane = styled.div`
	background: ${({ theme }) => theme.nColors['n-light']};
	border-radius: 16px !important;
	width: 100%;
	height: fit-content !important;

	@media ${({ theme }) => theme.media.md} {
		width: 30%;
		height: 100% !important;
	}
`;
export const RightPane = styled.div`
	background: ${({ theme }) => theme.nColors['n-light']};
	border-radius: 16px !important;
	width: 100%;
	height: 100%;
	@media ${({ theme }) => theme.media.md} {
		width: 70%;
	}
`;
export const MobileHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	border-bottom: 1px solid #eeeeee !important;
	padding: 16px 0;
	margin-bottom: 40px;

	@media ${({ theme }) => theme.media.md} {
		margin-top: 20px;
		display: none;
	}
`;

export const MobileCardDetails = styled.div`
	border: 1px solid #eeeeee !important;
	border-radius: 24px;
	padding: 30px;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;

	@media ${({ theme }) => theme.media.md} {
		display: none;
	}
`;

export const MobileTransferDetails = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;

	@media ${({ theme }) => theme.media.md} {
		display: none;
	}
`;

export const MobileFormWrapper = styled.div`
	width: 100%;
	margin-top: 40px;
`;

export const ButtonsControl = styled.div`
	gap: 16px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;

	@media ${({ theme }) => theme.media.md} {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		gap: 16px;
	}
`;

export const MerchantDetails = styled.div`
	background: #fffcf2 !important;
	border-bottom: 1px solid #fff5cc !important;
	border-top-left-radius: 16px !important;
	border-top-right-radius: 16px !important;
	padding: 16px;
	display: none;
	@media ${({ theme }) => theme.media.md} {
		display: block;
	}
`;
export const MerchantLogo = styled.div`
	height: 32px;
	width: 32px;
	border-radius: 50%;
	object-fit: cover;
`;

export const PaymentMethods = styled.div`
	display: none;
	@media ${({ theme }) => theme.media.md} {
		margin-top: 20px;
		display: block;
	}
`;

export const PaymentMethodItem = styled.div<{ active: boolean }>`
	display: flex;
	align-items: center;
	cursor: pointer;
	gap: 10px;
	padding: 16px;
	background: ${({ theme, active }) =>
		active ? theme.nColors['n-grey0'] : theme.nColors['n-light']};
	border-bottom: 0.3px solid #f2f2f2 !important;
`;

export const OrderDetails = styled.div`
	display: none;
	@media ${({ theme }) => theme.media.md} {
		border-bottom: ${({ theme }) => `1px solid ${theme.nColors['n-grey1']}`};
		border-top-left-radius: 16px !important;
		border-top-right-radius: 16px !important;
		padding: 16px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
`;

export const TransferDetails = styled.div`
	display: none;

	@media ${({ theme }) => theme.media.md} {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 10px;
		padding: 24px 16px;
		height: 100%;
	}
`;

export const AccountNumberWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	border: 1px solid #ccddff;
	padding: 15px;
	border-radius: 8px;
	margin-top: 20px;
`;

export const CopyBox = styled.div`
	background: ${({ theme }) => theme.nColors['n-grey1']};
	padding: 10px;
	border-radius: 8px;
	cursor: pointer;
`;

export const BankInfoWrapper = styled.div<{ bg?: string }>`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	background: ${({ bg }) => bg || '#F2F6FF'};
	padding: 16px;
	border-radius: 8px;
	margin-top: 10px;
`;

export const ClockIconWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 26px !important;
	height: 26px !important;
`;

export const NIconWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 16px !important;
	height: 16px !important;
`;

export const ErrorMessage = styled.div`
	text-align: center;
	width: 100%;
	background: ${({ theme }) => theme.nColors['n-grey1']};
	padding: 16px;
	border-radius: 8px;
	margin: 50px 0;
`;

export const NetworkIssues = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
	margin-top: 30px;
	width: 100%;
	padding: 15px;
	border-radius: 32px;
	background: ${({ theme }) => theme.nColors['n-yellow2']};
`;

export const Link = styled.a`
	text-decoration: none;
	color: ${({ theme }) => theme.nColors['n-dark']};
	font-weight: 500;
`;

export const GetHelpWrapper = styled.div`
	background: ${({ theme }) => theme.nColors['n-grey1']};
	border-radius: 8px;
	padding: 40px;
	margin-top: 20px;
	gap: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	width: 100%;
`;

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.span<{
	borderbottomcolor: string;
	color: string;
}>`
	width: 40px;
	height: 40px;
	border-radius: 50%;
	display: inline-block;
	box-sizing: border-box;
	border: ${({ color }) => `3px solid ${color}`};
	border-bottom-color: ${({ borderbottomcolor }) =>
		borderbottomcolor} !important;
	animation: ${rotate} 1s linear infinite;
`;

export const FormWrapper = styled.div`
	width: 100%;
	padding: 16px;
`;

export const FormRow = styled.div`
	width: 100%;
	margin-bottom: 20px;
`;

export const CardNumberWrapper = styled.div`
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 10px;
`;

export const CardInputWrapper = styled.div<FormInputProps>`
	display: inline-block;
	padding: 10px;
	border: ${({ isError, theme }) =>
		isError
			? `1px solid ${theme.nColors['n-red']}`
			: `1px solid ${theme.nColors['n-grey1']}`};
	border-radius: 8px;
	width: 100%;
`;

export const MobileControl = styled.div`
	display: block;
	@media ${({ theme }) => theme.media.md} {
		display: none;
	}
`;

export const MobileCardInputWrapper = styled.div<FormInputProps>`
	display: inline-block;
	padding: 10px;
	background: ${({ theme }) => theme.nColors['n-grey0']};
	border-bottom: ${({ isError, theme }) =>
		isError
			? `1px solid ${theme.nColors['n-red']}`
			: `1px solid ${theme.nColors['n-grey1']}`};
	border-top-left-radius: 16px !important;
	border-top-right-radius: 16px !important;
	width: 100%;
`;

export const CardNumberInput = styled.input<FormInputProps>`
	width: 100%;
	margin-top: 10px;
	font-size: 14px;
	border: none !important;
	outline: none !important;
`;

export const MobileCardNumberInput = styled.input<FormInputProps>`
	width: 100%;
	margin-top: 10px;
	font-size: 14px;
	border: none !important;
	outline: none !important;
	background: ${({ theme }) => theme.nColors['n-grey0']};
`;

export const UnderInputTextOption = styled.div<{
	top?: number;
	bottom?: number;
}>`
	font-style: normal;
	font-weight: 300;
	font-size: 1.2rem;
	line-height: 180%;
	color: ${({ theme }) => theme.colors.primary.green8};
	margin-bottom: ${({ bottom }) => (bottom ? `${bottom}px` : '8px')};
	display: flex;
	margin-right: 20.2rem;
	align-items: center;
`;

export const CvvTooltip = styled.div`
	background: ${({ theme }) => theme.nColors['n-dark']};
	color: ${({ theme }) => theme.nColors['n-light']};
	padding: 16px 10px;
	border-radius: 16px;
	width: 236px;
	visibility: hidden;
	display: flex;
	flex-direction: column;
	gap: 20px;
	position: absolute;
	right: 0;
	margin-top: 20px;
	z-index: 99999999 !important;
	opacity: 1 !important;
	&::before {
		border: 2px solid red;
		z-index: 9999;
		content: '';
		position: absolute;
		top: -9px;
		right: 0;
		transform: translateX(-50%);
		border-width: 0 10px 10px 10px;
		border-style: solid;
		border-color: transparent transparent
			${({ theme }) => theme.nColors['n-dark']} transparent;
	}
`;

export const PhoneNumberWrapper = styled.div`
	width: 100%;
	background: ${({ theme }) => theme.nColors['n-grey0']};
	padding: 16px;
	border-radius: 16px;
	margin: 50px 0;
`;

export const SavedCardBox = styled.div<{ color: string }>`
	display: flex;
	gap: 10px;
	align-items: center;
	padding: 16px;
	border-radius: 8px;
	width: 100%;
	cursor: pointer;
	border: ${({ color }) => `1px solid ${color}`};
`;

export const TrashIconWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 32px !important;
	height: 32px !important;
	cursor: pointer;
	border-radius: 8px;
	background: ${({ theme }) => theme.nColors['n-red2']};
`;

export const MobileFooter = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	position: absolute;
	right: 0;
	bottom: -5px;
	font-size: 12px;
	z-index: 3;
`;
export const SavedCardsWrapper = styled.div`
	width: 100%;
	margin-top: 30px;
	height: 220px;
	overflow-y: scroll;
`;
export const QrCodeWrapper = styled.div`
	background: ${({ theme }) => theme.nColors['n-grey1']};
	padding: 10px;
	border-radius: 16px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: fit-content;
	margin-top: 10px;
	position: relative;
	flex-direction: column;
`;

export const MainQrWrapper = styled.div`
	height: 90%;
	position: relative;
`;

export const QrScreenWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
`;
export const DownloadAppWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: calc(100% - 2rem);
	padding: 16px;
	border-radius: 16px;
	background: ${({ theme }) => theme.nColors['n-grey1']};
	position: absolute;
	bottom: 2rem;
	left: 1rem;
	right: 1rem;
	margin-bottom: 50px;

	@media ${({ theme }) => theme.media.md} {
		margin-bottom: 0;
	}
`;

export const BusinessName = styled.div`
	background: ${({ theme }) => theme.nColors['n-yellow1']};
	border-radius: 16px;
	padding: 10px;
	margin-bottom: 15px;
	margin-top: -40px;
	font-weight: 500;
`;

export const HowItWorks = styled.div`
	margin-top: 5px;
	margin-bottom: -5px;
	cursor: pointer;
`;

export const Cashback = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: -50px;
	margin-bottom: 30px;
	background: ${({ theme }) => theme.nColors['n-green1']};
	padding-top: 6px;
	padding-bottom: 6px;
	font-size: 12px;

	@media ${({ theme }) => theme.media.md} {
		margin-top: 0px;
		margin-bottom: -30px;
	}
`;

export const Earn = styled.div`
	font-weight: 700;
`;

export const GiftWrapper = styled.div`
  display: flex
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  background: ${({ theme }) => theme.nColors['n-green2']};
  padding: 3px 4px 1px 4px;
  border-radius: 10px;
`;

export const QrIconWrapper = styled.div`
  display: flex
  align-items: center;
  justify-content: center;
`;

export const PhoneWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 300px;
	margin-top: 20px;
`;

export const BankWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin-top: 20px;

	@media ${({ theme }) => theme.media.md} {
		width: 300px;
	}
`;

export const PhoneWrapperMobile = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 40px;
	margin-bottom: 20px;
`;

export const CustomerFeeBreakdown = styled.div`
  position: absolute;
  z-index: 999999999;
  background: ${({ theme }) => theme.nColors['n-light']};
  width: calc(100% - 2rem);
  padding: 16px;
  left: 1rem;
  right: 1rem;
  border-radius: 4px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 12px;
  top: 10%;

  @media ${({ theme }) => theme.media.md} {
    position: absolute;
    z-index: 999999999;
    background: ${({ theme }) => theme.nColors['n-light']};
    width: calc(100% - 3rem);
    padding: 16px;
    left: 1.5rem;
    right: 1.5rem;
    border-radius: 4px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 12px;
  }
`;
