import { GetHelpWrapper, MobileTransferDetails, TransferDetails } from '../style'
import { Typography } from 'kudi-component-library'
import { Button, Flex } from 'rebass'
import { ButtonText } from '../../styles'
import InfoIcon from '../../../assets/info-icon'
import RetryIcon from '../../../assets/retry'
import { ScreenTypes } from '../../../utils'

export default function AccountExpired({ setTimer, setCurrentSubScreen }: { setTimer: React.Dispatch<React.SetStateAction<number>>, setCurrentSubScreen: React.Dispatch<React.SetStateAction<ScreenTypes>> }) {
    return (
        <>
            <TransferDetails>
                <GetHelpWrapper style={{ paddingBottom: "70px" }}>
                    <InfoIcon />
                    <Typography color='dark' fontSize='16px' fontWeight={700}>Account has expired</Typography>
                    <Typography color='dark' fontSize='14px' lineHeight='18px'>
                        The account provided for this <br /> transaction has expired.
                    </Typography>
                </GetHelpWrapper>

                <Flex style={{ gap: "16px" }} width="100%" className="cursor-pointer" alignItems="center" mt="30px" justifyContent="space-between">
                    <Button
                        onClick={() => window.location.reload()}
                        textAlign="center"
                        bg="#FFCC00"
                        color="#121212"
                        variant="large"
                        width="100%"
                        height="50px"
                        className="cursor-pointer"
                        style={{ borderRadius: "8px" }}
                    >
                        <Flex justifyContent="center" alignItems="center" style={{ gap: "10px" }}>
                            <RetryIcon /> <ButtonText style={{ whiteSpace: "nowrap" }}>Try again</ButtonText>
                        </Flex>
                    </Button>
                    <Button
                        onClick={() => {
                            setTimer(30);
                            setCurrentSubScreen("ConfirmTransfer");
                        }}
                        textAlign="center"
                        bg="#FFFFFF"
                        color="#121212"
                        variant="large"
                        width="100%"
                        height="50px"
                        className="cursor-pointer"
                        style={{ border: "1px solid #E6E6E6", borderRadius: "8px" }}
                    >
                        <ButtonText>
                            I've sent the money
                        </ButtonText>
                    </Button>
                </Flex>
            </TransferDetails>

            <MobileTransferDetails>
                <GetHelpWrapper style={{ paddingBottom: "70px" }}>
                    <InfoIcon />
                    <Typography color='dark' fontSize='16px' fontWeight={700}>Account has expired</Typography>
                    <Typography color='dark' fontSize='14px' lineHeight='18px'>
                        The account provided for this <br /> transaction has expired.
                    </Typography>
                </GetHelpWrapper>

                <Flex style={{ gap: "16px" }} width="100%" className="cursor-pointer" alignItems="center" mt="30px" justifyContent="space-between">
                    <Button
                        onClick={() => window.location.reload()}
                        textAlign="center"
                        bg="#FFCC00"
                        color="#121212"
                        variant="large"
                        width="100%"
                        height="50px"
                        className="cursor-pointer"
                        style={{ borderRadius: "8px" }}
                    >
                        <Flex justifyContent="center" alignItems="center" style={{ gap: "10px" }}>
                            <RetryIcon /> <ButtonText style={{ whiteSpace: "nowrap" }}>Try again</ButtonText>
                        </Flex>
                    </Button>
                    <Button
                        onClick={() => {
                            setTimer(30);
                            setCurrentSubScreen("ConfirmTransfer");
                        }}
                        textAlign="center"
                        bg="#FFFFFF"
                        color="#121212"
                        variant="large"
                        width="100%"
                        height="50px"
                        className="cursor-pointer"
                        style={{ border: "1px solid #E6E6E6", borderRadius: "8px" }}
                    >
                        <ButtonText>
                            I've sent the money
                        </ButtonText>
                    </Button>
                </Flex>
            </MobileTransferDetails>
        </>

    )
}
