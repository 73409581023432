import { ReactComponent as NombaQrInActiveIconIcon } from "./qr-inactive.svg";

const NombaQrInActiveIcon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
        title?: string | undefined;
    }
> = (props) => <NombaQrInActiveIconIcon {...props} />;

export default NombaQrInActiveIcon;

