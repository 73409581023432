import { ReactComponent as GooglePlayIconIcon } from "./google-play.svg";

const GooglePlayIcon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
        title?: string | undefined;
    }
> = (props) => <GooglePlayIconIcon {...props} />;

export default GooglePlayIcon;

