import { NTheme } from "kudi-component-library";
import { Suspense } from "react";
import { Box, Flex } from 'rebass'
import { ThemeProvider } from "styled-components";
import { Alert, Rotate } from '../assets'
import { formatCurrency } from '../utils'
import Modal from './Modal'
import { AppBody, AppHeaderText, InfoText } from './styles'

export default function FailedResponse({ error, amount, id }: { error?: string, amount: number | undefined, id: string | undefined }) {
    const handleTryAgain = () => {
        window.location.href = `/checkout/${id}`
    }

    const handleClose = () => {
        window.close();
    }

    return (
        <ThemeProvider theme={NTheme}>
            <AppBody>
                <Flex justifyContent={"center"} alignItems={"center"} height={"100vh"}>
                    <Suspense fallback={<h1>Loading...</h1>}>
                        <Modal showModal={true} onClose={handleClose}>
                            <Flex bg="#FFFFFF" px="20px" py="60px" style={{ borderRadius: "4px" }} flexDirection="column" justifyContent="center" alignItems="center">
                                <Alert />
                                <AppHeaderText mt="20px">Failed transaction</AppHeaderText>
                                {error ?
                                    <InfoText textAlign="center" mt="20px">{error} </InfoText>
                                    :
                                    <>
                                        <InfoText textAlign="center" mt="20px">We couldn't confirm your payment of </InfoText> <br />
                                        <InfoText textAlign="center" color="n-dark" fontWeight={700}>{amount && formatCurrency(Number(amount))}</InfoText>
                                    </>
                                }
                                <Flex onClick={handleTryAgain} className="cursor-pointer" alignItems="center" mt="30px" justifyContent="center">
                                    <Box mr="10px"><Rotate /></Box> <InfoText fontWeight={500}>Try again</InfoText>
                                </Flex>
                            </Flex>
                        </Modal>
                    </Suspense>
                </Flex>
            </AppBody>
        </ThemeProvider>
    )
}
