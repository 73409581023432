import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NTheme } from "kudi-component-library";
import { useParams } from "react-router-dom";
import { Flex } from "rebass";
import { ThemeProvider } from "styled-components";
import { AppBody } from "./styles";
import { AppDispatch, RootState } from "../store";
import { confirmBankTransfer } from "../redux/action";
import Modal from "./Modal";
import Loader from "./Loader";
import SuccessResponse from "./v2/Responses/SuccessResponse";
import FailedResponse from "./v2/Responses/FailedResponse";

export default function PaymentCallback() {
  const { id } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const { confirmResponse, } = useSelector((state: RootState) => state.order);
  const [loading, setLoading] = useState<boolean>(true);

  const saveCard = localStorage.getItem("saveCard") === "true" ? true : false;

  useEffect(() => {
    if (id) {
      dispatch(confirmBankTransfer({ orderReference: id }));
    }
  }, [dispatch, id])

  useEffect(() => {
    if (confirmResponse?.code && confirmResponse?.code) {
      setLoading(false);
    }
  }, [confirmResponse])

  return (
    <ThemeProvider theme={NTheme}>
      <AppBody>
        {loading ?
          <Modal showModal={true} onClose={() => window.close()}>
            <Flex flexDirection="column" alignItems="center" py="100px">
              <Loader />
            </Flex>
          </Modal>
          :
          confirmResponse?.code === "00" && confirmResponse?.data?.status ?
            <SuccessResponse orderDetails={confirmResponse?.data?.order} saveCard={saveCard} />
            :
            <FailedResponse error={confirmResponse?.data?.message} />
        }
      </AppBody>
    </ThemeProvider>
  );
}
