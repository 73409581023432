import { useState } from "react";
import { useSelect } from "downshift";
import {
  InputWrapper,
  Label,
  DownShiftItem,
  InputItemErrorWrapper,
  DownShiftOptionsWrapper,
  DownShiftOption,
} from "./styles";
import { Typography } from "kudi-component-library";
import DropDownIcon from "./Icons/DropDownIcon";
import ErrorIcon from "./Icons/ErrorIcon";
import styled from "styled-components";

const StyledText = styled(Typography)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

interface SelectProps {
  label?: string;
  name: string;
  value: string;
  selectOptions: {
    text: string;
    value: string;
  }[];
  onChange: (e: { target: { name: string; value: string | null } }) => void;
  disabled?: boolean;
  error?: string;
  placeholder?: string;
  width?: string;
  textPrefix?: string;
  style?: React.CSSProperties;
}

function CustomSelect({
  label,
  name,
  onChange,
  selectOptions,
  disabled = false,
  error,
  placeholder,
  width,
  value,
  textPrefix,
}: SelectProps) {
  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect({
    items: selectOptions.map((item) => item.value),
    onSelectedItemChange: (e) =>
      onChange({ target: { name, value: e.selectedItem || "" } }),
  });

  const [open, setOpen] = useState<boolean>(false);
  const [isFocus, setIsfocus] = useState<boolean>(false);

  return (
    <InputWrapper
      width={width}
      onFocus={() => {
        setOpen(!open);
        setIsfocus(true);
      }}
      disabled={disabled}
      onBlur={() => setIsfocus(false)}
    >
      {label && (
        <Label htmlFor="hello" {...getLabelProps()}>
          {label}
        </Label>
      )}
      <DownShiftItem
        open={isOpen}
        isFocus={isFocus}
        type="button"
        {...getToggleButtonProps()}
        disabled={disabled}
        error={error}
        value={value}
      >
        {selectOptions.find((item) => item.value === value)?.text ? (
          <StyledText
            fontSize="14px"
            fontWeight={400}
            lineHeight="24px"
            color="n-grey5"
          >
            {`${textPrefix ? textPrefix : ""} ${
              selectOptions.find((item) => item.value === value)?.text
            }`}
          </StyledText>
        ) : (
          <Typography
            fontSize="14px"
            fontWeight={400}
            lineHeight="24px"
            color="n-grey5"
          >
            {placeholder}
          </Typography>
        )}
        <DropDownIcon />
      </DownShiftItem>
      <DownShiftOptionsWrapper open={isOpen} {...getMenuProps()}>
        {isOpen &&
          selectOptions.map((item, index) => (
            <DownShiftOption
              style={
                highlightedIndex === index
                  ? { backgroundColor: "#C6C6C6", textOverflow: "ellipsis" }
                  : { textOverflow: "ellipsis" }
              }
              key={`${item.value}${index}`}
              {...getItemProps({ item: item.value, index })}
            >
              {item.text}
            </DownShiftOption>
          ))}
      </DownShiftOptionsWrapper>

      {error ? (
        <InputItemErrorWrapper>
          <ErrorIcon /> <span> {error} </span>
        </InputItemErrorWrapper>
      ) : null}
    </InputWrapper>
  );
}

export default CustomSelect;
