import React from 'react';
import styled from 'styled-components';
import ErrorPageIcon from '../../assets/error-page';
import { Button, Typography } from 'kudi-component-library';

const MainWrapper = styled.div`
  background: ${({ theme }) => theme.nColors['n-light']};
  width: 100%;
  height: 100vh;
  padding: 40px;
  display: flex;
  gap: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  @media ${({ theme }) => theme.media.lg} {
    width: 50%;
    padding: 100px;
    height: 100%;
  }
`;

const ErrorTextBold = styled(Typography)`
  color: ${({ theme }) => theme.nColors['n-dark']};
  font-size: 48px;
  font-weight: 700;
  line-height: 56px !important;
`;

export default function ErrorScreen({ error }: { error: string | undefined }) {
  const goHome = () => {
    window.location.href = 'https://nomba.com';
  };

  const linkStatus = () => {
    if (error?.includes('inactive')) {
      return 'deactivated';
    }
    return 'deleted';
  };
  return (
    <MainWrapper>
      <ErrorPageIcon />
      <ErrorTextBold>
        Oops! We can't find <br /> that page.
      </ErrorTextBold>
      {linkStatus() === 'deactivated' ? (
        <Typography color="n-grey4">
          Sorry, this link has been disabled. kindly contact the link owner and retry. Thanks
        </Typography>
      ) : (
        <Typography color="n-grey4">
          Sorry, the url has been deleted by the user. Kindly check with the link owner for possible re-activation and
          retry. Thanks
        </Typography>
      )}

      <Button onClick={() => goHome()} variant="medium" width="50%" type="button">
        Go to Homepage
      </Button>
    </MainWrapper>
  );
}
