import React from 'react';
import { Typography, Avatar } from 'kudi-component-library';
import styled from 'styled-components';
import { Flex } from 'rebass';

const NavContainer = styled(Flex)`
  border-bottom: 1px solid #f2f2f2;
  background: #fff;
  width: 100%;
  height: 100px;
  @media (max-width: 600px) {
    padding: 10px;
  }
`;

interface PageNavProps {
  companyName: string | null | undefined;
  email: string;
}

function getInitials(fullName: string) {
  const words = fullName.split(' ');
  const initials = words.map((word) => word.charAt(0).toUpperCase());
  return initials.join('');
}

const PageNav: React.FC<PageNavProps> = ({ companyName, email }) => {
  // Fallback to email if companyName is undefined or null
  const displayName = companyName ?? email;

  return (
    <NavContainer justifyContent="center" px="120px" py="30px">
      <Flex style={{ gap: '10px' }} alignItems="center">
        <Avatar initials={getInitials(displayName)} size="large" />
        <Typography color="n-grey3" style={{ display: 'flex', gap: '5px' }}>
          By
          <Typography color="n-dark">{displayName}</Typography>
        </Typography>
      </Flex>
    </NavContainer>
  );
};

export default PageNav;
