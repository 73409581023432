import { BackButton } from 'kudi-component-library';
import { ReactNode } from 'react';
import { FooterLogo } from '../../assets';
import { ModalWrapper, ModalBackground, ModalContent, ModalContentWrapper, ModalFooter } from './style';

export interface ModalProps {
  showModal: boolean;
  onClose: () => void;
  children: ReactNode;
  backClick?: () => void;
  showBack?: boolean;
  noCloseIcon?: boolean;
  customDesktopWidth?: string;
  customTabletWidth?: string;
  modalContentStyles?: React.CSSProperties;
  padding?: string;
  closeBg?: string;
  padCloseButton?: boolean
}

const Content = ({
  onClose,
  showModal,
  children,
  showBack,
  backClick,
  customDesktopWidth,
  customTabletWidth,
  modalContentStyles,
  padding,
}: ModalProps) =>
  showModal ? (
    <ModalWrapper>
      <ModalBackground>
        <ModalContentWrapper
          className='no-scrollbar'
          customTabletWidth={customTabletWidth}
          customDesktopWidth={customDesktopWidth}
          padding={padding}
          style={{ ...modalContentStyles }}
        >
          <ModalContent className='no-scrollbar' onClick={(e) => e.stopPropagation()} padding={padding}>
            {showBack ? <BackButton onClick={backClick}>Back</BackButton> : null}
            {children}
          </ModalContent>

          <ModalFooter>
            <span style={{ marginRight: "10px" }}>Secured by</span> <FooterLogo />
          </ModalFooter>
        </ModalContentWrapper>
      </ModalBackground>
    </ModalWrapper>
  ) : null;

const Modal = ({ ...props }: ModalProps) => <Content {...{ ...props }} />;

export default Modal;