import { useEffect, useState } from "react";
import { Box, Button, Flex } from "rebass";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Spinner } from "../assets";
import InfoIcon from "../assets/info";
import VisaCard from "../assets/visa";
import MasterCard from "../assets/mastercard";
import VerveCard from "../assets/vervecard";
import {
    SecureParamsType,
    formatCurrency,
    getCardType,
    validateCardNumber,
} from "../utils";
import {
    AppHeader,
    ButtonText,
    CardInputLogo,
    CardNumberInput,
    CardNumberInputWrapper,
    ErrorText,
    FormInput,
    InfoText,
    PaymentMethodBox,
} from "./styles";
import { ScreenTypes } from "../utils";
import { AppDispatch, RootState } from "../store";
import { CheckoutRequest, createOrder } from "../redux/action";
import { errorResponse } from "../utils/toast";
import SecureAuth from "./SecureAuth";

export default function CardInput({
    amount,
    onMoveToNewScreen,
}: {
    amount: number | undefined;
    onMoveToNewScreen: (newscreen: ScreenTypes, error?: string) => void;
}) {
    const [cardNumber, setCardNumber] = useState("");
    const [cardType, setCardType] = useState("");
    const [expiry, setExpiry] = useState("");
    const [cvv, setCvv] = useState("");
    const [pin, setPin] = useState("");
    const [cardNumberError, setCardNumberError] = useState(false);
    const [dateError, setDateError] = useState(false);
    const [cvvError, setCvvError] = useState(false);
    const [cardError, setCardError] = useState(false);
    const [secureParams, setSecureParams] = useState<SecureParamsType>(null);

    const dispatch = useDispatch<AppDispatch>();
    const { isLoading, createOrderResponse } = useSelector(
        (state: RootState) => state.order
    );
    const { id } = useParams();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const rawValue = e.target.value.replace(/\s+/g, "");
        const spacedValue = formatWithSpaces(rawValue);

        if (rawValue.length > 20) {
            setCardNumberError(true);
            setCardNumber(spacedValue);
            return;
        }

        let hasError = false;
        const userCardType = getCardType(rawValue);
        setCardType(userCardType);
        if (userCardType === "Unknown") {
            hasError = true;
        }
        setCardNumberError(hasError);
        setCardNumber(spacedValue);
    };

    const handleCardInputBlur = () => {
        const isValid = validateCardNumber(cardNumber);
        setCardNumberError(!isValid);
        if (cardNumber === "") {
            setCardNumberError(false);
        }
    };

    const formatWithSpaces = (s: string): string => {
        return s.replace(/(\d{4})/g, "$1 ").trim();
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value;
        value = value.replace(/[^\d]/g, "");
        if (value.length > 2) {
            value = value.slice(0, 2) + "/" + value.slice(2);
        }
        setExpiry(value);
    };

    const handleCvvInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value;
        value = value.replace(/[^\d]/g, "");
        if (value.length > 4 || value.length < 2) {
            setCvvError(true);
        } else {
            setCvvError(false);
            setCvv(value);
        }
    };

    const handleInputBlur = () => {
        const [month, year] = expiry.split("/");
        const currentYear = new Date().getFullYear().toString().substr(-2);
        if (
            parseInt(month, 10) > 12 ||
            parseInt(month, 10) < 1 ||
            parseInt(year, 10) < Number(currentYear)
        ) {
            setDateError(true);
        } else {
            setDateError(false);
        }
    };

    const valiDated = () => {
        if (
            cardNumber === "" ||
            cvv === "" ||
            expiry === "" ||
            pin === "" ||
            dateError ||
            cvvError ||
            cardNumberError
        ) {
            return false;
        }
        return true;
    };

    const handleSubmit = () => {
        try {
            if (id) {
                setCardError(false);
                const [month, year] = expiry.split("/");
                const cardDetails = {
                    cardCVV: Number(cvv),
                    cardExpiryMonth: Number(month),
                    cardExpiryYear: Number(year),
                    cardNumber: cardNumber.replace(/\s+/g, ""),
                    cardPin: Number(pin),
                };

                // JSON stringify cardDetails
                const jsonCardDetails = JSON.stringify(cardDetails);

                const finalPayload: CheckoutRequest = {
                    cardDetails: jsonCardDetails,
                    key: "",
                    orderReference: id,
                    deviceInformation: {
                        httpBrowserLanguage: navigator.language,
                        httpBrowserJavaEnabled: "true",
                        httpBrowserJavaScriptEnabled: "true",
                        httpBrowserColorDepth: window.screen.colorDepth.toString(),
                        httpBrowserScreenHeight: window.screen.height.toString(),
                        httpBrowserScreenWidth: window.screen.width.toString(),
                        httpBrowserTimeDifference: new Date().getTimezoneOffset().toString(),
                        userAgentBrowserValue: navigator.userAgent,
                        deviceChannel: "Browser"
                    }
                };
                dispatch(createOrder(finalPayload));
            }
        } catch (error: any) {
            errorResponse("Something went wrong" || error.response.data.message);
        }
    };

    useEffect(() => {
        if (createOrderResponse === null) {
            return;
        }
        if (
            createOrderResponse &&
            createOrderResponse?.data?.responseCode === "S0" &&
            createOrderResponse?.data?.secureAuthenticationData?.jwt &&
            createOrderResponse?.data?.secureAuthenticationData?.md &&
            createOrderResponse?.data?.secureAuthenticationData?.acsUrl
        ) {
            const params: SecureParamsType = {
                jwt: createOrderResponse?.data?.secureAuthenticationData?.jwt,
                md: createOrderResponse?.data?.secureAuthenticationData?.md,
                acsUrl: createOrderResponse?.data?.secureAuthenticationData?.acsUrl,
            };
            setSecureParams(params);
        } else if (
            createOrderResponse &&
            createOrderResponse?.data?.responseCode === "T0"
        ) {
            onMoveToNewScreen("CardOtp", createOrderResponse?.data?.message);
        } else if (
            createOrderResponse &&
            createOrderResponse?.data?.responseCode === "00"
        ) {
            sessionStorage.setItem('showResponse', "true");
            onMoveToNewScreen("SuccessResponse")
        } else {
            setCardError(true);
        }
    }, [createOrderResponse, onMoveToNewScreen]);
    const goHome = () => {
        onMoveToNewScreen("PaymentMethods");
    };

    return (
        <Box style={{ height: "100%" }}>
            <Flex flexDirection="column" alignItems="flex-start">
                <AppHeader>Card</AppHeader>
                <InfoText>Get to make instant payment with your card</InfoText>
            </Flex>

            {secureParams !== null ? (
                <SecureAuth params={secureParams} />
            ) : (
                    <>
                        <PaymentMethodBox>
                            <InfoText fontSize="12px">Card number</InfoText>
                            <CardNumberInputWrapper>
                                <CardNumberInput
                                    key="cardInput"
                                    isError={cardNumberError || cardError}
                                    type="tel"
                                    placeholder="0000 0000 0000 0000 0000"
                                    value={cardNumber}
                                    onChange={(e) => handleChange(e)}
                                    onBlur={handleCardInputBlur}
                                />
                                <CardInputLogo>
                                    {cardType === "Visa" ? (
                                        <VisaCard />
                                    ) : cardType === "Mastercard" ? (
                                        <MasterCard />
                                    ) : cardType === "Verve" ? <VerveCard /> : null}
                                </CardInputLogo>
                            </CardNumberInputWrapper>
                            {cardNumberError ? (
                                <ErrorText>Your card number is invalid</ErrorText>
                            ) : (
                                    <InfoText fontSize="12px">
                                        {" "}
                                You can pay using your Mastercard, Visa and Verve card{" "}
                                    </InfoText>
                                )}
                            <Flex mt="10px" justifyContent="space-between">
                                <Box width="90%" mr="20px">
                                    <InfoText fontSize="12px">Card expiry</InfoText>
                                    <FormInput
                                        isError={dateError || cardError}
                                        type="text"
                                        placeholder="MM/YY"
                                        maxLength={5}
                                        value={expiry}
                                        onChange={handleInputChange}
                                        onBlur={handleInputBlur}
                                    />
                                    {dateError && <ErrorText>Invalid date</ErrorText>}
                                </Box>

                                <Box width="90%">
                                    <Flex alignItems="center">
                                        <InfoText fontSize="12px">CVV</InfoText>
                                        <Box ml="5px" className="tooltip-container">
                                            <InfoIcon />
                                            <span className="tooltip-text">
                                                Your card's CVV is a 2 to 4-digit <br /> number found on
                                            the back.
                                        </span>
                                        </Box>
                                    </Flex>
                                    <FormInput
                                        isError={cvvError || cardError}
                                        onChange={handleCvvInputChange}
                                        type="text"
                                        placeholder="123"
                                        maxLength={4}
                                    />
                                    {cvvError && <ErrorText>Invalid cvv</ErrorText>}
                                </Box>
                            </Flex>
                            <Box mt="10px">
                                <InfoText fontSize="12px">Card pin</InfoText>
                                <FormInput
                                    isError={cardError}
                                    type="password"
                                    name="pin"
                                    onChange={(e) => setPin(e.target.value)}
                                />
                            </Box>
                        </PaymentMethodBox>

                        {cardError && (
                            <Box mb="20px">
                                <ErrorText>
                                    Your card was declined. Try paying with another card or choose
                                    another payment method
                            </ErrorText>
                            </Box>
                        )}
                        {valiDated() ? (
                            <Button
                                textAlign="center"
                                onClick={handleSubmit}
                                mb="10px"
                                bg="#FFCC00"
                                color="#121212"
                                variant="large"
                                width="100%"
                                className="cursor-pointer"
                                disabled={isLoading}
                                opacity={isLoading ? "0.3" : ""}
                            >
                                {isLoading ? (
                                    <Spinner />
                                ) : (
                                        <ButtonText>Pay {formatCurrency(Number(amount))}</ButtonText>
                                    )}
                            </Button>
                        ) : (
                                <Button
                                    disabled={true}
                                    opacity="0.4"
                                    mb="10px"
                                    bg="#FFCC00"
                                    color="#121212"
                                    variant="large"
                                    width="100%"
                                    className="cursor-pointer"
                                >
                                    <ButtonText>Pay {formatCurrency(Number(amount))}</ButtonText>
                                </Button>
                            )}
                        <Button
                            onClick={goHome}
                            bg="#FFFFFF"
                            color="#121212"
                            variant="large"
                            width="100%"
                            height="40px"
                            className="cursor-pointer custom-btn"
                        >
                            <ButtonText>Change payment method</ButtonText>
                        </Button>
                    </>
                )}
        </Box>
    );
}
