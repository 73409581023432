import { NTheme } from "kudi-component-library"
import { Flex } from "rebass"
import { ThemeProvider } from "styled-components"
import CheckoutForm from "./CheckoutForm"
import { AppBody } from "./styles"

export default function Home() {

    return (
        <ThemeProvider theme={NTheme}>
            <AppBody>
                <Flex justifyContent={"center"} alignItems={"center"} height={"100vh"}>
                    <CheckoutForm />
                </Flex>
            </AppBody>
        </ThemeProvider>
    )
}
