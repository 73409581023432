import { useState } from 'react';
import { Box, Flex } from 'rebass'
import { MobileCardDetails, TransferDetails } from '../style'
import { OtpInput, Typography } from 'kudi-component-library'
import { fetchSavedCards, verifyPhone } from '../../../redux/action';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../store';
import Loader from '../../Loader';
import { SavedCard, ScreenTypes } from '../../../utils';
import { CountdownTimer } from '../../../utils/Countdown';
import { Orderdetails } from '../../../redux/slice';
import PhoneOtpIcon from '../../../assets/phone-otp';
import { errorResponse } from '../../../utils/toast';

export default function PhoneOtp({ setCards, orderDetails, otpMsg, setOtpMsg, setCurrentSubScreen }: { otpMsg: string, orderDetails: Orderdetails | null, setCards: React.Dispatch<React.SetStateAction<SavedCard[]>>, setCurrentSubScreen: React.Dispatch<React.SetStateAction<ScreenTypes>>, setOtpMsg: React.Dispatch<React.SetStateAction<string>> }) {
    const [otpValue, setOtpValue] = useState<string>('');
    const [otpError, setOtpError] = useState<string>('');
    const [inProgress, setInprogress] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [timer, setTimer] = useState<number>(1);

    const dispatch = useDispatch<AppDispatch>();

    function handleChange(val: string) {
        setOtpValue(val);
        setOtpError('')
        if (val.length === 4) {
            getSavedCards(val);
        }
    }

    const getSavedCards = async (val: string) => {
        try {
            if (!orderDetails) return;
            setInprogress(true);
            const payload = {
                orderReference: orderDetails?.data?.order?.orderId,
                otp: val
            }
            const result = await dispatch(fetchSavedCards(payload));
            if (result?.payload?.code !== "00") {
                setOtpError(result?.payload?.description);
                setInprogress(false);
            } else {
                setOtpError("");
                setCards(result?.payload?.data?.tokenizedCardDataList);
                setCurrentSubScreen("SavedCards");
                setInprogress(false);
            }
        } catch (error: any) {
            setInprogress(false);
            setOtpError(error.message || "An error occured, please try again")
        }
    }

    const resendOtp = async () => {
        try {
            if (!orderDetails) return;
            setLoading(true);
            const payload = {
                orderReference: orderDetails?.data?.order?.orderId,
            }
            const result = await dispatch(verifyPhone(payload))
            setLoading(false);
            setTimer(1);
            setOtpMsg(result?.payload?.data?.message);
        } catch (error: any) {
            setLoading(false);
            errorResponse(error.message || "An error occured, please try again")
        }
    }

    return (
        <>
            <TransferDetails style={{ marginTop: "30px", textAlign: "center" }}>
                {inProgress || loading ?
                    <Flex flexDirection="column" style={{ gap: "10px" }}>
                        <Loader />
                        <Typography color="n-grey4" fontWeight={500}>
                            Please wait
                        </Typography>
                    </Flex>
                    :
                    <>
                        <PhoneOtpIcon />

                        <Typography color="n-dark" fontWeight={500} lineHeight='18px'>
                            {otpMsg}
                        </Typography>

                        <Box mt="40px">
                            <OtpInput
                                width="100%"
                                inputsNumber={4}
                                name="token"
                                value={otpValue}
                                onChange={(e) => handleChange(e.target.value)}
                                error={otpError}
                            />
                        </Box>
                        {timer > 0 ?
                            <Flex mt="30px" alignItems="center" style={{ gap: "5px" }}>
                                <Typography color='dark' fontWeight={500}>Resend OTP in
                                </Typography>
                                <CountdownTimer  {...{ minutes: timer, seconds: 0, cb: () => setTimer(0), color: "dark", fontSize: "14px" }} />
                                <Typography color='dark' fontWeight={500}>secs
                                </Typography>
                            </Flex>
                            :
                            <Box mt="30px" onClick={() => resendOtp()}>
                                <Typography className='cursor-pointer' color='n-yellow8' fontWeight={500}>Resend OTP
                                </Typography>
                            </Box>
                        }
                    </>
                }
            </TransferDetails>

            <MobileCardDetails style={{ height: "50vh", alignItems: "center", textAlign: "center" }}>
                {inProgress || loading ?
                    <Flex flexDirection="column" style={{ gap: "10px" }}>
                        <Loader />
                        <Typography color="n-grey4" fontWeight={500}>
                            Please wait
                        </Typography>
                    </Flex>
                    :
                    <>
                        <PhoneOtpIcon />

                        <Typography color="n-dark" fontWeight={500} lineHeight='18px'>
                            {otpMsg}
                        </Typography>

                        <Box mt="40px">
                            <OtpInput
                                width="100%"
                                inputsNumber={4}
                                name="token"
                                value={otpValue}
                                onChange={(e) => handleChange(e.target.value)}
                                error={otpError}
                            />
                        </Box>
                        {timer > 0 ?
                            <Flex mt="30px" alignItems="center" style={{ gap: "5px" }}>
                                <Typography color='dark' fontWeight={500}>Resend OTP in
                                </Typography>
                                <CountdownTimer  {...{ minutes: timer, seconds: 0, cb: () => setTimer(0), color: "dark", fontSize: "14px" }} />
                                <Typography color='dark' fontWeight={500}>secs
                                </Typography>
                            </Flex>
                            :
                            <Box onClick={() => resendOtp()} mt="30px">
                                <Typography className='cursor-pointer' color='n-yellow8' fontWeight={500}>Resend OTP
                                </Typography>
                            </Box>
                        }
                    </>
                }
            </MobileCardDetails>
        </>

    )
}
