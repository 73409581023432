import { NTheme } from "kudi-component-library";
import { useEffect, useState, Suspense } from "react";
import { Flex } from 'rebass'
import { ThemeProvider } from "styled-components";
import Modal from "../../Modal";
import { AppBody, AppHeaderText, InfoText } from "../../styles";
import { ErrorMessage, } from "../style";
import CloseModalNew from "../../../assets/closemodal-new";
import SuccessNewIcon from "../../../assets/success-new";
import { Orderdetails } from "../../../redux/slice";
import ConfirmModal from "../../ConfirmModal";
import { handleCallbackUrl } from "../../../utils";

export default function PhoneSuccess({ orderDetails }: { orderDetails: Orderdetails | null }) {
    const [showModal, setShowModal] = useState<boolean>(false);

    const handleClose = () => {
        setShowModal(false)
    }
    const confirm = () => {
        if (orderDetails) {
            handleCallbackUrl(orderDetails?.data?.order?.callbackUrl, orderDetails?.data?.order.orderId, orderDetails?.data?.order?.orderReference);
        }
    }

    useEffect(() => {
        setTimeout(() => {
            if (orderDetails) {
                handleCallbackUrl(orderDetails?.data?.order?.callbackUrl, orderDetails?.data?.order.orderId, orderDetails?.data?.order?.orderReference);
            }
        }, 5000);
    }, [orderDetails])

    return (
        <ThemeProvider theme={NTheme}>
            <AppBody>

                <Flex justifyContent={"center"} alignItems={"center"}>
                    <Suspense fallback={<h1>Loading...</h1>}>
                        <Modal showModal={true} onClose={handleClose}>
                            <Flex justifyContent="flex-end" mb="10px">
                                <CloseModalNew onClick={() => setShowModal(true)} style={{ cursor: "pointer" }} />
                                {showModal &&
                                    <ConfirmModal confirm={confirm} showModal={showModal} handleClose={handleClose} />
                                }
                            </Flex>
                            <Flex bg="#FFFFFF" height="100%" style={{ borderRadius: "16px" }} flexDirection="column" justifyContent="center" alignItems="center">
                                <SuccessNewIcon />
                                <AppHeaderText mt="10px">
                                    Phone number confirmed!
                                </AppHeaderText>

                                <ErrorMessage>
                                    <InfoText fontSize="12px" textAlign="center">
                                        Your card has been securely saved and it will be available for your next checkout with
                                        <span style={{ color: "#AA8800" }}> {orderDetails?.data?.order?.customerEmail}.</span> You will now be redirected to your merchant's site. Thank you
                                    </InfoText>
                                </ErrorMessage>
                            </Flex>
                        </Modal>
                    </Suspense>
                </Flex>

            </AppBody>
        </ThemeProvider>
    )
}
