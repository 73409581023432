import { useState } from "react";
import { Box, Flex } from "rebass";
import { MobileCardDetails, TransferDetails } from "../../v2/style";
import { BackButton, OtpInput, Typography } from "kudi-component-library";
import { CardDetailsType } from "../../../utils";
import { CheckoutRequest, createSandboxOrder } from "../../../redux/action";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../store";
import Loader from "../../Loader";
import { ScreenTypes } from "../../../utils";
import PinLockIcon from "../../../assets/pin-lock";

export default function CardPin({
  setOtpMessage,
  cardDetails,
  orderReference,
  saveCard,
  setCurrentSubScreen,
}: {
  setOtpMessage: React.Dispatch<React.SetStateAction<string>>;
  setCurrentSubScreen: React.Dispatch<React.SetStateAction<ScreenTypes>>;
  saveCard: boolean;
  cardDetails: CardDetailsType;
  orderReference: string;
}) {
  const [pinValue, setPinValue] = useState<string>("");
  const [pinError, setPinError] = useState<string>("");
  const [inProgress, setInProgress] = useState<boolean>(false);

  const dispatch = useDispatch<AppDispatch>();

  function handleChange(val: string) {
    setPinValue(val);
    setPinError("");
    if (val.length === 4) {
      handleSubmit(val);
    }
  }

  const handleSubmit = async (val: string) => {
    cardDetails.cardPin = Number(val);
    // JSON stringify cardDetails
    const jsonCardDetails = JSON.stringify(cardDetails);
    const finalPayload: CheckoutRequest = {
      cardDetails: jsonCardDetails,
      key: "",
      orderReference: orderReference,
      saveCard: saveCard,
      deviceInformation: {
        httpBrowserLanguage: navigator.language,
        httpBrowserJavaEnabled: "true",
        httpBrowserJavaScriptEnabled: "true",
        httpBrowserColorDepth: window.screen.colorDepth.toString(),
        httpBrowserScreenHeight: window.screen.height.toString(),
        httpBrowserScreenWidth: window.screen.width.toString(),
        httpBrowserTimeDifference: new Date().getTimezoneOffset().toString(),
        userAgentBrowserValue: navigator.userAgent,
        deviceChannel: "Browser",
      },
    };
    setInProgress(true);
    const result = await dispatch(createSandboxOrder(finalPayload));
    if (result.payload.code !== "00") {
      setPinError(result?.payload?.description);
      setInProgress(false);
      return;
    }
    if (result.payload.code === "00" && result.payload.data.status) {
      setOtpMessage(result?.payload?.data?.message);
      setCurrentSubScreen("CardOtp");
      setInProgress(false);
    }
  };

  return (
    <>
      <TransferDetails style={{ marginTop: "30px", textAlign: "center" }}>
        {inProgress ? (
          <Flex flexDirection="column" style={{ gap: "10px" }}>
            <Loader />
            <Typography color="n-grey4" fontWeight={500}>
              Please wait
            </Typography>
          </Flex>
        ) : (
          <>
            <Typography color="n-dark" fontWeight={500}>
              Please enter your 4-digit card PIN to <br />
              complete transaction
            </Typography>

            <Box mt="40px">
              <OtpInput
                width="100%"
                inputsNumber={4}
                name="token"
                value={pinValue}
                onChange={(e) => handleChange(e.target.value)}
                error={pinError}
              />
            </Box>
            <Box mt="16px">
              <BackButton onClick={() => setCurrentSubScreen("CardInput")}>
                Go Back
              </BackButton>
            </Box>
          </>
        )}
      </TransferDetails>

      <MobileCardDetails
        style={{ height: "50vh", alignItems: "center", textAlign: "center" }}
      >
        {inProgress ? (
          <Flex
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            style={{ gap: "10px" }}
          >
            <Loader />
            <Typography color="n-grey4" fontWeight={500}>
              Please wait
            </Typography>
          </Flex>
        ) : (
          <>
            <PinLockIcon />
            <Typography
              color="n-dark"
              fontWeight={500}
              style={{ marginTop: "30px" }}
            >
              Please enter your 4-digit card PIN to <br />
              complete transaction
            </Typography>

            <Box mt="40px">
              <OtpInput
                width="100%"
                inputsNumber={4}
                name="token"
                value={pinValue}
                onChange={(e) => handleChange(e.target.value)}
                error={pinError}
              />
            </Box>
            <Box mt="16px">
              <BackButton onClick={() => setCurrentSubScreen("CardInput")}>
                Go Back
              </BackButton>
            </Box>
          </>
        )}
      </MobileCardDetails>
    </>
  );
}
