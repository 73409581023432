/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Badge, Button, Typography } from "kudi-component-library";
import styled from "styled-components";
import { Box, Flex } from "rebass";
import { InputItemErrorWrapper } from "../../components/styles";
import ErrorIcon from "../../components/Icons/ErrorIcon";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { SubscriptionDetailsResponse } from "../../redux/slice";
import ConfirmModal from "../../components/ConfirmModal";
import { cancelPaymentLinkSubscription } from "../../redux/action";
import { errorResponse, successResponse } from "../../utils/toast";

const SubscriptionPageBoxCard = styled(Box)`
  width: 100%;
  padding: 20px;
  display: flex;
  margin-top: 30px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const ButtonBox = styled(Flex)`
  gap: 20px;
  width: 588px;
  @media (max-width: 600px) {
    width: 100%;
  }
`;
const SubscriptionDetailsCard = styled(Box)`
  border-radius: 24px;
  background: #fff;
  height: auto;
  width: 588px;
  padding: 32px 34px;
  margin: 50px 0;
  @media (max-width: 600px) {
    width: 100%;
    padding: 24px 26px;
  }
`;
interface SubscriptionPageDetailsProps {
  subscriptionDetailsResponse: SubscriptionDetailsResponse | null;
  refetchSubscription: () => void;
}

const SubscriptionPageDetails: React.FC<SubscriptionPageDetailsProps> = ({
  subscriptionDetailsResponse,
  refetchSubscription,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const { isLoading } = useSelector((state: RootState) => state.order);

  const confirm = async () => {
    setShowModal(false);

    try {
      const result = await dispatch(
        cancelPaymentLinkSubscription(
          subscriptionDetailsResponse?.data?.subscriptionId as string
        )
      );
      if (result?.payload?.data) {
        successResponse(result?.payload?.data);
        refetchSubscription();
      } else {
        errorResponse(
          result?.payload?.data || "An error occured, please try again"
        );
      }
    } catch (error: any) {
      errorResponse(error.message || "An error occured, please try again");
    }
  };

  const handleClose = () => {
    setShowModal(false);
  };

  if (
    !subscriptionDetailsResponse ||
    subscriptionDetailsResponse.code.includes("4") ||
    subscriptionDetailsResponse.code.includes("5")
  ) {
    return (
      <SubscriptionDetailsCard>
        <InputItemErrorWrapper>
          <ErrorIcon />{" "}
          <span>
            {" "}
            {subscriptionDetailsResponse?.description ||
              "Error Loading Page"}{" "}
          </span>
        </InputItemErrorWrapper>
      </SubscriptionDetailsCard>
    );
  }

  return (
    <SubscriptionPageBoxCard>
      <Flex
        style={{
          gap: "32px",
          textAlign: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
        mb="32px"
      >
        <Typography variant="n-text5">
          {" "}
          {subscriptionDetailsResponse?.data?.paymentLinkTitle}
        </Typography>
        <Typography
          variant="n-text2"
          color="n-grey3"
          style={{ maxWidth: "80%" }}
        >
          Manage payment methods for this subscription
        </Typography>
      </Flex>
      <SubscriptionDetailsCard>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          style={{ borderBottom: "solid 1px #F2F2F2" }}
          pb="20px"
        >
          <Flex flexDirection="column" style={{ gap: "5px" }}>
            <Typography variant="n-text2" fontWeight={500} color="n-grey6">
              {subscriptionDetailsResponse?.data?.merchantDetails?.email}
            </Typography>
            <Typography variant="n-text1" color="n-grey3">
              {subscriptionDetailsResponse?.data?.merchantDetails?.firstName}{" "}
              {subscriptionDetailsResponse?.data?.merchantDetails?.lastName}
            </Typography>
          </Flex>
          <Badge
            type="status"
            variant={
              subscriptionDetailsResponse?.data?.status === "ACTIVE"
                ? "success"
                : "error"
            }
          >
            {subscriptionDetailsResponse?.data?.status}
          </Badge>
        </Flex>
        <Flex
          justifyContent="space-between"
          style={{ borderBottom: "solid 1px #F2F2F2" }}
          py="20px"
        >
          <Typography variant="n-text2" color="n-grey3">
            Card used
          </Typography>
          <Typography variant="n-text2">
            {subscriptionDetailsResponse?.data?.cardUsed}
          </Typography>
        </Flex>
        <Flex justifyContent="space-between" pt="20px">
          <Typography variant="n-text2" color="n-grey3">
            Expires in
          </Typography>
          <Typography variant="n-text2">
            {subscriptionDetailsResponse?.data?.expiresIn
              ?.toString()
              .slice(0, -2) +
              "/" +
              subscriptionDetailsResponse?.data?.expiresIn
                ?.toString()
                .slice(-2)}
          </Typography>
        </Flex>
      </SubscriptionDetailsCard>
      {subscriptionDetailsResponse?.data?.status === "ACTIVE" && (
        <ButtonBox
          flexDirection="column"
          mt="20px"
          mb="200px"
          justifyContent="center"
          alignItems="center"
        >
          <Button
            variant="medium"
            width="100%"
            onClick={() => {
              setShowModal(true);
            }}
            loading={isLoading}
          >
            Cancel Subscription
          </Button>
        </ButtonBox>
      )}
      {showModal && (
        <ConfirmModal
          confirm={confirm}
          handleClose={handleClose}
          showModal={showModal}
          description="By confirming to cancel this Subscription. You'll no longer be charged and you will lose access to this service."
        />
      )}
    </SubscriptionPageBoxCard>
  );
};

export default SubscriptionPageDetails;
