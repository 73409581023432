import { useState } from "react";
import { Box, Flex } from "rebass";
import { MobileCardDetails, TransferDetails } from "../../v2/style";
import { BackButton, OtpInput, Typography } from "kudi-component-library";
import { checkoutSandboxOtp } from "../../../redux/action";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../store";
import Loader from "../../Loader";
import { ScreenTypes } from "../../../utils";
import { CountdownTimer } from "../../../utils/Countdown";
import PhoneOtpIcon from "../../../assets/phone-otp";

export default function CardOtp({
  saveCard,
  otpMessage,
  orderReference,
  setCurrentSubScreen,
  onMoveToNewScreen,
}: {
  saveCard: boolean;
  onMoveToNewScreen: (
    newscreen: ScreenTypes,
    error: string,
    saveCard: boolean
  ) => void;
  otpMessage: string;
  setCurrentSubScreen: React.Dispatch<React.SetStateAction<ScreenTypes>>;
  orderReference: string;
}) {
  const [otpValue, setOtpValue] = useState<string>("");
  const [otpError, setOtpError] = useState<string>("");
  const [otpMsg, setOtpMsg] = useState<string>(otpMessage);
  const [inProgress, setInprogress] = useState<boolean>(false);
  const [timer, setTimer] = useState<number>(1);

  const dispatch = useDispatch<AppDispatch>();

  function handleChange(val: string) {
    setOtpValue(val);
    setOtpError("");
    if (val.length === 6) {
      handleSubmit(val);
    }
  }

  const handleSubmit = async (val: string) => {
    setInprogress(true);
    const enteredOtp = val.substring(0, 4);
    const payload = {
      otp: enteredOtp,
      orderReference: orderReference,
    };
    if (orderReference) {
      const result = await dispatch(checkoutSandboxOtp(payload));
      if (result.payload.code !== "00") {
        setOtpError(result?.payload?.description);
        setInprogress(false);
      } else if (result?.payload?.data.status) {
        setInprogress(false);
        setOtpError("");
        onMoveToNewScreen("SuccessResponse", "", saveCard);
      } else {
        setInprogress(false);
        onMoveToNewScreen(
          "FailedResponse",
          result?.payload?.data?.message,
          saveCard
        );
      }
    }
  };

  const resendOtp = async () => {
    setInprogress(true);
    const enteredOtp = otpValue.substring(0, 4);
    const payload = {
      otp: enteredOtp,
      orderReference: orderReference,
    };
    if (orderReference) {
      const result = await dispatch(checkoutSandboxOtp(payload));
      setInprogress(false);
      setTimer(1);
      setOtpMsg(
        result?.payload?.data?.message || "An error occured, please try again"
      );
    }
  };

  return (
    <>
      <TransferDetails style={{ marginTop: "30px", textAlign: "center" }}>
        {inProgress ? (
          <Flex flexDirection="column" style={{ gap: "10px" }}>
            <Loader />
            <Typography color="n-grey4" fontWeight={500}>
              Please wait
            </Typography>
          </Flex>
        ) : (
          <>
            <Typography color="n-dark" fontWeight={500} lineHeight="18px">
              {otpMsg}
            </Typography>

            <Box mt="40px">
              <OtpInput
                width="100%"
                inputsNumber={6}
                name="token"
                value={otpValue}
                onChange={(e) => handleChange(e.target.value)}
                error={otpError}
              />
            </Box>
            {timer > 0 ? (
              <Flex mt="30px" alignItems="center" style={{ gap: "5px" }}>
                <Typography color="dark" fontWeight={500}>
                  Resend OTP in
                </Typography>
                <CountdownTimer
                  {...{
                    minutes: timer,
                    seconds: 0,
                    cb: () => setTimer(0),
                    color: "dark",
                    fontSize: "14px",
                  }}
                />
                <Typography color="dark" fontWeight={500}>
                  secs
                </Typography>
              </Flex>
            ) : (
              <Box mt="30px">
                <Typography
                  onClick={() => resendOtp()}
                  className="cursor-pointer"
                  color="n-yellow8"
                  fontWeight={500}
                >
                  Resend OTP
                </Typography>
              </Box>
            )}
            <Box mt="16px">
              <BackButton onClick={() => setCurrentSubScreen("CardPin")}>
                Go Back
              </BackButton>
            </Box>
          </>
        )}
      </TransferDetails>

      <MobileCardDetails
        style={{ height: "50vh", alignItems: "center", textAlign: "center" }}
      >
        {inProgress ? (
          <Flex flexDirection="column" style={{ gap: "10px" }}>
            <Loader />
            <Typography color="n-grey4" fontWeight={500}>
              Please wait
            </Typography>
          </Flex>
        ) : (
          <>
            <PhoneOtpIcon />

            <Typography
              color="n-dark"
              fontWeight={500}
              lineHeight="18px"
              style={{ marginTop: "30px" }}
            >
              {otpMsg}
            </Typography>

            <Box mt="40px">
              <OtpInput
                width="100%"
                inputsNumber={6}
                name="token"
                value={otpValue}
                onChange={(e) => handleChange(e.target.value)}
                error={otpError}
              />
            </Box>
            {timer > 0 ? (
              <Flex mt="30px" alignItems="center" style={{ gap: "5px" }}>
                <Typography color="dark" fontWeight={500}>
                  Resend OTP in
                </Typography>
                <CountdownTimer
                  {...{
                    minutes: timer,
                    seconds: 0,
                    cb: () => setTimer(0),
                    color: "dark",
                    fontSize: "14px",
                  }}
                />
                <Typography color="dark" fontWeight={500}>
                  secs
                </Typography>
              </Flex>
            ) : (
              <Box mt="30px">
                <Typography
                  onClick={() => resendOtp()}
                  className="cursor-pointer"
                  color="n-yellow8"
                  fontWeight={500}
                >
                  Resend OTP
                </Typography>
              </Box>
            )}
            <Box mt="16px">
              <BackButton onClick={() => setCurrentSubScreen("CardPin")}>
                Go Back
              </BackButton>
            </Box>
          </>
        )}
      </MobileCardDetails>
    </>
  );
}
