import styled from 'styled-components';
import { convertHexToRGBA } from '../../utils';

export const ModalWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  z-index: 999;
  display: block;
`;

export const ModalBackground = styled.div`
  background-color: ${({ theme }) => convertHexToRGBA(theme.nColors['n-dark'], 0.3)};
  width: 100vw;
  height: 100vh;
  cursor: pointer;
`;

export const ModalContentWrapper = styled.div<{
  customTabletWidth?: string;
  customDesktopWidth?: string;
  padding?: string;
}>`
  position: absolute;
  bottom: 0;
  background-color: ${({ theme }) => theme.nColors['n-light']};
  width: 100%;
  padding: ${({ padding }) => padding || '4rem'};
  border-radius: 16px !important;
  cursor: default;
  height: fit-content !important;

  @media ${({ theme }) => theme.media.md} {
    bottom: 50%;
    left: 50%;
    transform: translate(-50%, 50%);
    border-radius: ${({ padding }) => (padding === '0' ? '' : '0.5rem')};
    max-width: ${({ customTabletWidth }) => customTabletWidth || '50rem'};
  }

  @media ${({ theme }) => theme.media.lg} {
    max-width: ${({ customDesktopWidth }) => customDesktopWidth || '50rem'};
  }
`;

export const ModalContent = styled.div<{ padding?: string }>`
  position: relative;
  min-height: 5rem;
  overflow-y: auto;
  max-height: 85vh;
  border-radius: ${({ padding }) => (padding === '0' ? '2rem 2rem 0px 0px' : 'unset')};
`;

export const ModalClose = styled.div<{ closeBg?: string; padCloseButton?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
  background-color: ${({ theme, closeBg }) => closeBg || theme.colors.primary.black6};
  cursor: pointer;
  position: absolute;
  right: 0;
  top: -55px;
  z-index: 3;
  ${({ padCloseButton }) => {
    if (padCloseButton) {
      return `
        right: 20px;
        top: 20px;
      `;
    }
  }}
`;