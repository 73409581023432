import { ReactComponent as RetryIconIcon } from "./retry.svg";

const RetryIcon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
        title?: string | undefined;
    }
> = (props) => <RetryIconIcon {...props} />;

export default RetryIcon;

