import { useEffect, useState } from 'react';
import { IFetchBankResponse, KtaOrderResponse, Orderdetails } from '../../../redux/slice';
import { ScreenTypes } from '../../../utils';
import { Typography } from 'kudi-component-library';
import FadeIn from 'react-fade-in/lib/FadeIn';
import { MobileCardDetails, TransferDetails, MobileControl, BankWrapper } from '../style';
import { Button, Flex } from 'rebass';
import { ButtonText, ErrorText } from '../../styles';
import Ussd from './Ussd';
import CustomSelect from '../../CustomSelect';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store';
import { sendUssdCode } from '../../../redux/action';
import Loader from '../../Loader';
interface UssdProps {
  orderDetails: Orderdetails | null;
  ktaResponse: KtaOrderResponse | null;
  onMoveToNewScreen: (newscreen: ScreenTypes, error: string, saveCard: boolean) => void;
  changePaymentMethod: () => void;
  customId?: string;
  setShowCustomModal?: () => void;
  fetchBankResponse: IFetchBankResponse | null;
}

const USSDSelectBank = ({
  orderDetails,
  ktaResponse,
  changePaymentMethod,
  onMoveToNewScreen,
  fetchBankResponse,
}: UssdProps) => {
  const dispatch = useDispatch<AppDispatch>();

  const { inProgress, ussdCodeResponse } = useSelector((state: RootState) => state.order);

  const [currentSubScreen, setCurrentSubScreen] = useState<ScreenTypes>('USSD');
  const [fetchedBank, setFetchedBank] = useState<IFetchBankResponse | null>(null);

  const [ussdBankId, setUssdBankId] = useState<string>('');
  const [bankName, setBankName] = useState<string>('');
  const [bankCode, setBankCode] = useState<string>('');
  const [processor, setProcessor] = useState<string>('');
  const [processorCodeName, setProcessorCodeName] = useState<string>('');
  const [ussdCodeFormat, setUssdCodeFormat] = useState<string>('');
  const [enabled, setEnabled] = useState<boolean>(false);
  const [promptMessage, setPromptMessage] = useState<string>('');
  const [orderReference, setOrderReference] = useState<string>('');

  const moveToSubScreen = (screen: ScreenTypes) => {
    setCurrentSubScreen(screen);
  };

  useEffect(() => {
    if (fetchBankResponse) {
      setFetchedBank(fetchBankResponse);
    }
  }, [fetchBankResponse]);

  const handleBankSelectChange = (event: any) => {
    const selectedBankCode = event.target.value;
    const selectedBank = fetchedBank?.data?.ussdCode.find((bank) => bank.bankCode === selectedBankCode);

    if (selectedBank) {
      setUssdBankId(selectedBank.ussdBankId);
      setBankName(selectedBank.bankName);
      setBankCode(selectedBank.bankCode);
      setProcessor(selectedBank.processor);
      setProcessorCodeName(selectedBank.processorCodeName);
      setUssdCodeFormat(selectedBank.ussdCodeFormat);
      setEnabled(selectedBank.enabled);
      setPromptMessage(selectedBank.promptMessage);
      setOrderReference(orderDetails?.data?.order?.orderReference || '');

      dispatch(
        sendUssdCode({
          ussdCode: {
            ussdBankId: selectedBank?.ussdBankId,
            bankName: selectedBank?.bankName,
            bankCode: selectedBank?.bankCode,
            processor: selectedBank?.processor,
            processorCodeName: selectedBank?.processorCodeName,
            ussdCodeFormat: selectedBank?.ussdCodeFormat,
            enabled: selectedBank?.enabled,
            promptMessage: selectedBank?.promptMessage,
          },
          orderId: orderDetails?.data?.order?.orderId,
        })
      );
    }
  };

  useEffect(() => {
    if (ussdCodeResponse && ussdCodeResponse?.data?.success) {
      moveToSubScreen('USSD2');
    }
  }, [ussdCodeResponse]);

  return (
    <FadeIn delay={100}>
      <>
        {currentSubScreen === 'USSD' &&
          (inProgress ? (
            <Flex
              justifyContent="center"
              flexDirection="column"
              alignItems="center"
              mt="auto"
              mb="auto"
              style={{ height: '45vh' }}
            >
              <Loader />
            </Flex>
          ) : (
            <>
              <TransferDetails>
                <Typography
                  style={{
                    textAlign: 'center',
                  }}
                  color="n-dark"
                  fontWeight={500}
                >
                  Choose your bank to start the payment
                </Typography>

                <BankWrapper>
                  <CustomSelect
                    label="Select your bank"
                    name="selectBank"
                    onChange={handleBankSelectChange}
                    selectOptions={
                      fetchedBank?.data?.ussdCode?.map((bank) => ({
                        text: bank.bankName,
                        value: bank.bankCode,
                      })) || []
                    }
                    placeholder="Select your bank"
                    value={bankCode}
                  />
                </BankWrapper>
                {ussdCodeResponse && !ussdCodeResponse?.data?.success && (
                  <ErrorText>Unable to Generate USSD Code, Please try again</ErrorText>
                )}
              </TransferDetails>

              <MobileCardDetails>
                <Typography style={{ textAlign: 'center' }} color="n-dark" fontWeight={500}>
                  Choose your bank to start the payment
                </Typography>

                {/* <PhoneWrapperMobile>
                  <PhoneNumberInput
                    name="phoneNumber"
                    label="Phone Number"
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                    onlyCountries={['ng']}
                  />
                </PhoneWrapperMobile> */}

                <BankWrapper>
                  <CustomSelect
                    label="Select your bank"
                    name="selectBank"
                    onChange={handleBankSelectChange}
                    selectOptions={
                      fetchedBank?.data?.ussdCode?.map((bank) => ({
                        text: bank.bankName,
                        value: bank.bankCode,
                      })) || []
                    }
                    placeholder="Select your bank"
                    value={bankCode}
                  />
                </BankWrapper>

                {ussdCodeResponse && !ussdCodeResponse?.data?.success && (
                  <ErrorText style={{ marginTop: '10px' }}>Unable to Generate USSD Code, Please try again</ErrorText>
                )}

                <MobileControl onClick={() => changePaymentMethod()} style={{ marginTop: '20px' }}>
                  <Button
                    bg="#FFFFFF"
                    color="#121212"
                    variant="large"
                    width="100%"
                    height="60px"
                    className="cursor-pointer change-btn"
                  >
                    <ButtonText>Change payment method</ButtonText>
                  </Button>
                </MobileControl>
              </MobileCardDetails>
            </>
          ))}

        {currentSubScreen === 'USSD2' && (
          <Ussd
            orderDetails={orderDetails}
            ktaResponse={ktaResponse}
            onMoveToNewScreen={onMoveToNewScreen}
            changePaymentMethod={changePaymentMethod}
            ussdBankId={ussdBankId}
            bankName={bankName}
            bankCode={bankCode}
            processor={processor}
            processorCodeName={processorCodeName}
            ussdCodeFormat={ussdCodeFormat}
            enabled={enabled}
            promptMessage={promptMessage}
            orderReference={orderReference}
            ussdCodeResponse={ussdCodeResponse}
          />
        )}
      </>
    </FadeIn>
  );
};

export default USSDSelectBank;
