import { NTheme } from "kudi-component-library";
import { Suspense, useEffect } from "react";
import { Flex } from 'rebass'
import { ThemeProvider } from "styled-components";
import Success from "../assets/success";
import { formatCurrency } from '../utils'
import Modal from './Modal'
import { AppBody, AppHeaderText, InfoText } from './styles'

export default function SuccessResponse({ redirectUrl, amount }: { redirectUrl: string | undefined, amount: number | undefined }) {
    const handleClose = () => {
        window.close();
    }

    useEffect(() => {
        setTimeout(() => {
            if (redirectUrl) {
                window.location.href = redirectUrl;
            }
        }, 1000);
    }, [redirectUrl])

    return (
        <ThemeProvider theme={NTheme}>
            <AppBody>
                <Flex justifyContent={"center"} alignItems={"center"} height={"100vh"}>
                    <Suspense fallback={<h1>Loading...</h1>}>
                        <Modal showModal={true} onClose={handleClose}>
                            <Flex bg="#FFFFFF" px="20px" py="60px" style={{ borderRadius: "4px" }} flexDirection="column" justifyContent="center" alignItems="center">
                                <Success />
                                <AppHeaderText mt="20px">Successful payment</AppHeaderText>
                                <InfoText textAlign="center" mt="20px">We have confirmed your payment of</InfoText> <br />
                                <InfoText textAlign="center" color="n-dark" fontWeight={700}>{amount && formatCurrency(Number(amount))}</InfoText>
                            </Flex>

                        </Modal>
                    </Suspense>
                </Flex>
            </AppBody>
        </ThemeProvider>
    )
}
