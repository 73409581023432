import { ReactComponent as ErrorPageIconIcon } from "./error-page.svg";

const ErrorPageIcon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
        title?: string | undefined;
    }
> = (props) => <ErrorPageIconIcon {...props} />;

export default ErrorPageIcon;

