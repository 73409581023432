import { ReactComponent as FailedIconIcon } from "./failed.svg";

const FailedIcon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
        title?: string | undefined;
    }
> = (props) => <FailedIconIcon {...props} />;

export default FailedIcon;

