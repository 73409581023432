import axios from "axios";
const baseUrl = process.env.REACT_APP_BASE_URL as string;

export const handleApiCall = async (
  url: string,
  method: string,
  body?: {} | null
) => {
  const options = {
    method: method,
    headers: {
      "content-type": "application/json",
    },
    data: body && body,
    url: `${baseUrl}${url}`,
  };
  const response = await axios(options);
  return response;
};
