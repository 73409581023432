import React, { Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import { useParams } from 'react-router-dom';
import { fetchSubscriptionDetails } from '../../redux/action';
import Loader from '../../components/Loader';
import { Box, Flex } from 'rebass';
import styled from 'styled-components';
import PageNav from '../components/PageNav';
import PageFooter from '../components/PageFooter';
import SubscriptionPageDetails from './SubscriptionPageDetails';

const SubscriptionPageBody = styled(Box)`
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0.3) 100%),
    linear-gradient(240deg, #ffe9d6 -9.62%, #fff 100%);
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SubscriptionPage: React.FC = () => {
  const { id } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const { isLoading, subscriptionDetailsResponse } = useSelector((state: RootState) => state.order);

  useEffect(() => {
    if (id) {
      dispatch(fetchSubscriptionDetails({ orderId: id }));
    }
  }, [dispatch, id]);

  return (
    <Suspense fallback={<Loader />}>
      {isLoading ? (
        <Flex
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
          mt="auto"
          mb="auto"
          style={{ height: '100%' }}
        >
          <Loader />
        </Flex>
      ) : (
        <>
          <SubscriptionPageBody>
            {subscriptionDetailsResponse?.data?.merchantDetails && (
              <PageNav
                companyName={subscriptionDetailsResponse?.data?.merchantDetails?.businessName}
                email={subscriptionDetailsResponse?.data?.merchantDetails?.email}
              />
            )}
            <SubscriptionPageDetails
              subscriptionDetailsResponse={subscriptionDetailsResponse}
              refetchSubscription={() => {
                dispatch(fetchSubscriptionDetails({ orderId: id as string }));
              }}
            />
            <PageFooter />
          </SubscriptionPageBody>
        </>
      )}
    </Suspense>
  );
};

export default SubscriptionPage;
