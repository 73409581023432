import { ReactComponent as OtherCardIcon } from "./other-card.svg";

const OtherCards: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
        title?: string | undefined;
    }
> = (props) => <OtherCardIcon {...props} />;

export default OtherCards;

