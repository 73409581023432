import { ReactComponent as MessageIconIcon } from "./message.svg";

const MessageIcon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
        title?: string | undefined;
    }
> = (props) => <MessageIconIcon {...props} />;

export default MessageIcon;

