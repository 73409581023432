import { ReactComponent as TransferInActiveIconIcon } from "./transfer-inactive.svg";

const TransferInActiveIcon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
        title?: string | undefined;
    }
> = (props) => <TransferInActiveIconIcon {...props} />;

export default TransferInActiveIcon;

