import { NTheme } from 'kudi-component-library';
import { useEffect, useRef } from 'react';
import { ThemeProvider } from 'styled-components';
import { useLocation } from 'react-router-dom';
import { AppBody } from '../styles';

const MpgsPage = () => {
  const location = useLocation();
  const mpgsUrl = location.state?.mpgsUrl;

  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    // Automatically submit the form on component mount
    if (formRef.current) {
      formRef.current.submit();
    }
  }, []);

  // Regular expressions to extract values
  const formActionUrlMatch = mpgsUrl.match(/action="([^"]+)"/);
  const paReqMatch = mpgsUrl.match(/name="PaReq" value="([^"]+)"/);
  const termUrlMatch = mpgsUrl.match(/name="TermUrl" value="([^"]+)"/);
  const mdMatch = mpgsUrl.match(/name="MD" value="([^"]+)"/);

  // Extracted values
  const formActionUrl = formActionUrlMatch ? formActionUrlMatch[1] : '';
  const paReq = paReqMatch ? paReqMatch[1] : '';
  const termUrl = termUrlMatch ? termUrlMatch[1] : '';
  const md = mdMatch ? mdMatch[1] : '';

  return (
    <ThemeProvider theme={NTheme}>
      <AppBody style={{ width: '100vw', height: '100vh' }}>
        {mpgsUrl && (
          <div>
            <head>
              <title>Process Secure Payment</title>
              <meta http-equiv="content-type" content="text/html;charset=UTF-8" />
              <meta name="description" content="Process Secure Payment" />
              <meta name="robots" content="noindex" />
              <style>
                {`
                  body {
                    font-family: "Trebuchet MS", sans-serif;
                    background-color: #ffffff;
                  }
                  #msg {
                    border: 5px solid #666;
                    background-color: #fff;
                    margin: 20px;
                    padding: 25px;
                    max-width: 40em;
                    border-radius: 10px;
                  }
                  #submitButton {
                    text-align: center;
                  }
                  #footnote {
                    font-size: 0.8em;
                  }
                `}
              </style>
            </head>
            <body>
              <form ref={formRef} name="echoForm" method="POST" action={formActionUrl} acceptCharset="UTF-8">
                <input type="hidden" name="PaReq" value={paReq} />
                <input type="hidden" name="TermUrl" value={termUrl} />
                <input type="hidden" name="MD" value={md} />
                <noscript>
                  <div id="msg">
                    <div id="submitButton">
                      <input type="submit" value="Click here to continue" className="button" />
                    </div>
                  </div>
                </noscript>
              </form>
            </body>
          </div>
        )}
      </AppBody>
    </ThemeProvider>
  );
};

export default MpgsPage;
