import { Flex } from "rebass"
import { CircleLogo } from "../../assets"

export default function Loader() {
    return (
        <Flex mt="20px" width={100} className="progress-container">
            <div className="loader-img background-image">
                <CircleLogo />
            </div>
            <span className="loader"></span>
        </Flex>
    )
}
