import { ReactComponent as SuccessIcon } from "./success.svg";

const Success: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
        title?: string | undefined;
    }
> = (props) => <SuccessIcon {...props} />;

export default Success;

