import { OtpInput } from "kudi-component-library";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Flex } from "rebass"
import { SmartPhoneLogo, Spinner } from "../assets";
import { CountdownTimer } from "../utils/Countdown";
import { AppHeader, ButtonText, InfoText } from "./styles"
import { AppDispatch, RootState } from "../store";
import { checkoutOtp } from "../redux/action";
import { ScreenTypes } from "../utils";

export default function CardOtp({ onMoveToNewScreen, reference, message }: { message?: string, reference: string | undefined, onMoveToNewScreen: (newscreen: ScreenTypes, error?: string) => void }) {
    const [timer, setTimer] = useState<number>(5);
    const [otpValue, setOtpValue] = useState<string>('');
    const [otpError, setOtpError] = useState<string>('');
    const [inProgress, setInprogress] = useState<boolean>(false);

    const dispatch = useDispatch<AppDispatch>();
    const { otpResponse } = useSelector((state: RootState) => state.order);

    function handleChange(val: string) {
        setOtpValue(val);
        setOtpError('')
    }

    const handleSubmit = () => {
        setInprogress(true);
        const payload = {
            otp: otpValue,
            orderReference: reference
        }
        reference && dispatch(checkoutOtp(payload));
    }

    useEffect(() => {
        if (otpResponse === null) {
            return;
        }
        if (otpResponse?.data?.status) {
            setInprogress(false);
            sessionStorage.setItem('showResponse', "true");
            onMoveToNewScreen("SuccessResponse")
        } else {
            setInprogress(false);
            onMoveToNewScreen("FailedResponse", otpResponse?.data?.message || "An error occured, please try again")
        }
    }, [otpResponse, onMoveToNewScreen]);

    return (
        <Box>
            <Flex flexDirection="column" justifyContent="center" alignItems="center">
                <Box mb="20px"><SmartPhoneLogo /></Box>
                <AppHeader>Enter OTP</AppHeader>
                <InfoText textAlign="center">{message ? message : "Please enter the OTP code sent to the mobile number associated with your bank account."} </InfoText>
            </Flex>
            <Box mt="40px">
                <OtpInput
                    width="100%"
                    inputsNumber={6}
                    name="token"
                    value={otpValue}
                    onChange={(e) => handleChange(e.target.value)}
                    error={otpError}
                />
            </Box>
            {otpValue === "" || otpValue.length < 6 ?
                <Button disabled opacity="0.4" mt="40px" mb="10px" bg="#FFCC00" color="#121212" variant="large" width="100%" height="40px" className="cursor-pointer">
                    <ButtonText>Confirm</ButtonText>
                </Button>
                :
                <Button disabled={inProgress} opacity={inProgress ? "0.4" : ""} justifyContent="center" alignItems="center" textAlign="center" onClick={handleSubmit} mt="40px" mb="10px" bg="#FFCC00" color="#121212" variant="large" width="100%" className="cursor-pointer">
                    {inProgress ? <Spinner /> : <ButtonText>Confirm</ButtonText>}
                </Button>
            }

            {timer === 0 ?
                <InfoText color="n-dark" fontWeight={700} textAlign="center" className="cursor-pointer">Resend Code</InfoText>
                :
                <InfoText textAlign="center" mt="10px">Receive OTP within <CountdownTimer  {...{ minutes: timer, seconds: 0, cb: () => setTimer(0) }} /></InfoText>
            }
        </Box>
    )
}
