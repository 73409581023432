import { ReactComponent as PinLockIconIcon } from "./pin-lock.svg";

const PinLockIcon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
        title?: string | undefined;
    }
> = (props) => <PinLockIconIcon {...props} />;

export default PinLockIcon;

