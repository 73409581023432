import { useEffect, useState } from 'react';
import {
	BankInfoWrapper,
	Link,
	NIconWrapper,
	NetworkIssues,
	TransferDetails,
	Spinner,
	MobileTransferDetails,
} from '../style';
import {
	NIcons,
	IconsEnum,
	Typography,
	NTheme as theme,
} from 'kudi-component-library';
import { Flex, Button } from 'rebass';
import { ButtonText, WhiteBox } from '../../styles';
import { CountdownTimer } from '../../../utils/Countdown';
import RetryIcon from '../../../assets/retry';
import NetworkIcon from '../../../assets/network';
import ProgressBar from '../../ProgressBar';
import { confirmBankTransfer } from '../../../redux/action';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store';
import { ScreenTypes } from '../../../utils';

export default function ConfirmTransfer({
	orderReference,
	setCurrentSubScreen,
	onMoveToNewScreen,
}: {
	setCurrentSubScreen: React.Dispatch<React.SetStateAction<ScreenTypes>>;
	orderReference: string | undefined;
	onMoveToNewScreen: (
		newscreen: ScreenTypes,
		error: string,
		saveCard: boolean
	) => void;
}) {
	const [timer, setTimer] = useState<number>(10);
	const [elapsedTime, setElapsedTime] = useState<number>(0);
	const [isHalfTime, setIsHalftime] = useState<boolean>(false);

	const dispatch = useDispatch<AppDispatch>();
	const { confirmResponse } = useSelector((state: RootState) => state.order);

	const calculateProgressBarWidth = () => {
		const totalDurationInSeconds = timer * 60;
		const percentageRemaining =
			((totalDurationInSeconds - elapsedTime) / totalDurationInSeconds) * 100;
		return `${percentageRemaining}%`;
	};

	useEffect(() => {
		orderReference &&
			dispatch(confirmBankTransfer({ orderReference: orderReference }));
	}, [orderReference, dispatch]);

	useEffect(() => {
		if (confirmResponse === null) {
			return;
		}
		if (confirmResponse?.data?.status) {
			onMoveToNewScreen('SuccessResponse', '', false);
			return;
		}
		if (!confirmResponse?.data?.status && timer === 0) {
			onMoveToNewScreen(
				'FailedResponse',
				confirmResponse?.data?.message,
				false
			);
			return;
		}
	}, [confirmResponse, timer, onMoveToNewScreen]);

	return (
		<>
			<TransferDetails>
				<BankInfoWrapper
					bg={
						timer === 0 ? theme.nColors['n-red1'] : theme.nColors['n-yellow2']
					}
				>
					<Typography fontSize='14px' color='dark'>
						{timer === 0 &&
							'This transaction has taken longer than usual? Kindly try again or get help, to continue. Thank you.'}
						{timer !== 0 && (
							<>
								{isHalfTime
									? "Taking a bit long? You don't need to stay here; we'll notify you once it's confirmed..."
									: 'Please wait. We are confirming your payment, It might take a few minutes...'}
							</>
						)}
					</Typography>
					{timer > 0 && (
						<Flex width='50%' justifyContent='flex-end'>
							<Spinner borderbottomcolor='#121212' color='#FFCC00' />
						</Flex>
					)}
				</BankInfoWrapper>

				<WhiteBox width='100%' style={{ padding: '10px', marginTop: '30px' }}>
					<Flex
						justifyContent='center'
						alignItems='center'
						width='60%'
						style={{ gap: '10px' }}
					>
						<Typography
							style={{ whiteSpace: 'nowrap' }}
							color='n-grey3a'
							fontSize='12px'
							fontWeight={500}
						>
							{timer === 0 ? 'Time elapsed' : 'Wait time'}
						</Typography>
						{timer > 0 && (
							<CountdownTimer
								{...{
									minutes: timer,
									seconds: 0,
									cb: () => setTimer(0),
									setElapsedTime: setElapsedTime,
									setIsHalftime: setIsHalftime,
									color: '#8C8C8C',
								}}
							/>
						)}
						<NIconWrapper>
							<NIcons
								icon={IconsEnum.OUTLINE_CLOCK}
								width='100%'
								height='100%'
							/>
						</NIconWrapper>
						<ProgressBar width={calculateProgressBarWidth()} />
					</Flex>
				</WhiteBox>

				{timer === 0 ? (
					<>
						<Flex
							style={{ gap: '5px' }}
							width='100%'
							className='cursor-pointer'
							alignItems='center'
							mt='30px'
							justifyContent='space-between'
						>
							<Button
								onClick={() => setCurrentSubScreen('ConfirmTransfer')}
								textAlign='center'
								bg='#FFCC00'
								color='#121212'
								variant='large'
								width='100%'
								height='50px'
								className='cursor-pointer'
								style={{ borderRadius: '8px' }}
							>
								<Flex
									justifyContent='center'
									alignItems='center'
									style={{ gap: '10px' }}
								>
									<RetryIcon />{' '}
									<ButtonText style={{ whiteSpace: 'nowrap' }}>
										Try again
									</ButtonText>
								</Flex>
							</Button>
							<Button
								onClick={() => setCurrentSubScreen('GetHelp')}
								textAlign='center'
								bg='#FFFFFF'
								color='#121212'
								variant='large'
								width='100%'
								height='50px'
								className='cursor-pointer'
								style={{ border: '1px solid #E6E6E6', borderRadius: '8px' }}
							>
								<Flex
									justifyContent='center'
									alignItems='center'
									style={{ gap: '10px' }}
								>
									<NIconWrapper>
										<NIcons
											icon={IconsEnum.OUTLINE_HELP}
											width='100%'
											height='100%'
										/>
									</NIconWrapper>
									<ButtonText>Get help</ButtonText>
								</Flex>
							</Button>
						</Flex>

						<NetworkIssues>
							<NetworkIcon />
							<Typography fontSize='14px' color='n-grey5'>
								Worried about bank's network? <Link href='/'>Click here</Link>
							</Typography>
						</NetworkIssues>
					</>
				) : (
					<Flex mt='40px'>
						<Typography
							onClick={() => setCurrentSubScreen('GetHelp')}
							fontSize='16px'
							color='n-yellow8'
							fontWeight={500}
							style={{ cursor: 'pointer' }}
						>
							Need help with this transaction?
						</Typography>
					</Flex>
				)}
			</TransferDetails>

			<MobileTransferDetails>
				<BankInfoWrapper
					bg={
						timer === 0 ? theme.nColors['n-red1'] : theme.nColors['n-yellow2']
					}
				>
					<Typography fontSize='14px' color='dark'>
						{timer === 0 &&
							'This transaction has taken longer than usual? Kindly try again or get help, to continue. Thank you.'}
						{timer !== 0 && (
							<>
								{isHalfTime
									? "Taking a bit long? You don't need to stay here; we'll notify you once it's confirmed..."
									: 'Please wait. We are confirming your payment, It might take a few minutes...'}
							</>
						)}
					</Typography>
					{timer > 0 && (
						<Flex width='50%' justifyContent='flex-end'>
							<Spinner borderbottomcolor='#121212' color='#FFCC00' />
						</Flex>
					)}
				</BankInfoWrapper>

				<WhiteBox width='100%' style={{ padding: '10px', marginTop: '30px' }}>
					<Flex
						justifyContent='center'
						alignItems='center'
						width='60%'
						style={{ gap: '10px' }}
					>
						<Typography
							style={{ whiteSpace: 'nowrap' }}
							color='n-grey3a'
							fontSize='12px'
							fontWeight={500}
						>
							{timer === 0 ? 'Time elapsed' : 'Wait time'}
						</Typography>
						{timer > 0 && (
							<CountdownTimer
								{...{
									minutes: timer,
									seconds: 0,
									cb: () => setTimer(0),
									setElapsedTime: setElapsedTime,
									setIsHalftime: setIsHalftime,
									color: '#8C8C8C',
								}}
							/>
						)}
						<NIconWrapper>
							<NIcons
								icon={IconsEnum.OUTLINE_CLOCK}
								width='100%'
								height='100%'
							/>
						</NIconWrapper>
						<ProgressBar width={calculateProgressBarWidth()} />
					</Flex>
				</WhiteBox>

				{timer === 0 ? (
					<>
						<Flex
							style={{ gap: '5px' }}
							width='100%'
							className='cursor-pointer'
							alignItems='center'
							mt='30px'
							justifyContent='space-between'
						>
							<Button
								onClick={() => setCurrentSubScreen('ConfirmTransfer')}
								textAlign='center'
								bg='#FFCC00'
								color='#121212'
								variant='large'
								width='100%'
								height='50px'
								className='cursor-pointer'
								style={{ borderRadius: '8px' }}
							>
								<Flex
									justifyContent='center'
									alignItems='center'
									style={{ gap: '10px' }}
								>
									<RetryIcon />{' '}
									<ButtonText style={{ whiteSpace: 'nowrap' }}>
										Try again
									</ButtonText>
								</Flex>
							</Button>
							<Button
								onClick={() => setCurrentSubScreen('GetHelp')}
								textAlign='center'
								bg='#FFFFFF'
								color='#121212'
								variant='large'
								width='100%'
								height='50px'
								className='cursor-pointer'
								style={{ border: '1px solid #E6E6E6', borderRadius: '8px' }}
							>
								<Flex
									justifyContent='center'
									alignItems='center'
									style={{ gap: '10px' }}
								>
									<NIconWrapper>
										<NIcons
											icon={IconsEnum.OUTLINE_HELP}
											width='100%'
											height='100%'
										/>
									</NIconWrapper>
									<ButtonText>Get help</ButtonText>
								</Flex>
							</Button>
						</Flex>

						<NetworkIssues>
							<NetworkIcon />
							<Typography fontSize='14px' color='n-grey5'>
								Worried about bank's network? <Link href='/'>Click here</Link>
							</Typography>
						</NetworkIssues>
					</>
				) : (
					<Flex mt='40px' justifyContent='center'>
						<Typography
							onClick={() => setCurrentSubScreen('GetHelp')}
							fontSize='16px'
							color='n-yellow8'
							fontWeight={500}
							style={{ cursor: 'pointer', textAlign: 'center' }}
						>
							Need help with this transaction?
						</Typography>
					</Flex>
				)}
			</MobileTransferDetails>
		</>
	);
}
