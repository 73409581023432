import { useState } from 'react'
import FadeIn from 'react-fade-in/lib/FadeIn'
import { CardNumberInput, CardInputWrapper, CardNumberWrapper, FormRow, FormWrapper, OrderDetails, TransferDetails, CvvTooltip, MobileCardDetails, MobileFormWrapper, MobileCardInputWrapper, MobileCardNumberInput, MobileControl } from '../../v2/style'
import { Flex, Box, Button } from 'rebass'
import {  Typography } from 'kudi-component-library'
import { CardDetailsType, formatCurrency, getCardType, handleCallbackUrl, validateCardNumber } from '../../../utils'
import CloseModalNew from '../../../assets/closemodal-new'
import { IOrderDetails } from '../../../redux/slice'
import { ButtonText, CardInputLogo, ErrorText } from '../../styles'
import VisaCard from '../../../assets/visa'
import MasterCard from '../../../assets/mastercard'
import VerveCard from '../../../assets/vervecard'
import OtherCards from '../../../assets/othercards'
import CvvHelp from '../../../assets/cvv-help'
import CardPin from './CardPin'
import { ScreenTypes } from '../../../utils'
import CardOtp from './CardOtp'
import ErrorCardIcon from '../../../assets/error-card'
import AtmCardActiveIcon from '../../../assets/card-active'
import ConfirmModal from '../../ConfirmModal'

export default function Card({ orderDetails, changePaymentMethod, onMoveToNewScreen }: { orderDetails: IOrderDetails | null, onMoveToNewScreen: (newscreen: ScreenTypes, error: string, saveCard: boolean) => void, changePaymentMethod: () => void }) {
    const [cardNumberError, setCardNumberError] = useState<boolean>(false);
    const [cardNumber, setCardNumber] = useState<string>("");
    const [cardType, setCardType] = useState<string>("");
    const [dateError, setDateError] = useState<boolean>(false);
    const [expiry, setExpiry] = useState<string>("");
    const [cvvError, setCvvError] = useState<boolean>(false);
    const [cvv, setCvv] = useState<string>("");
    const [currentSubScreen, setCurrentSubScreen] = useState<ScreenTypes>("CardInput");
    const [cardDetails, setCardDetails] = useState<CardDetailsType | null>(null);
    const [otpMessage, setOtpMessage] = useState<string>("");

    const [showModal, setShowModal] = useState<boolean>(false);

    const formatWithSpaces = (s: string): string => {
        return s.replace(/(\d{4})/g, "$1 ").trim();
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const rawValue = e.target.value.replace(/\s+/g, "");
        const spacedValue = formatWithSpaces(rawValue);

        if (rawValue.length > 20) {
            setCardNumberError(true);
            setCardNumber(spacedValue);
            return;
        }

        let hasError = false;
        const userCardType = getCardType(rawValue);
        setCardType(userCardType);
        if (userCardType === "Unknown") {
            hasError = true;
        }
        setCardNumberError(hasError);
        setCardNumber(spacedValue);
    };

    const handleCardInputBlur = () => {
        const isValid = validateCardNumber(cardNumber);
        setCardNumberError(!isValid);
        if (cardNumber === "") {
            setCardNumberError(false);
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value;
        value = value.replace(/[^\d]/g, "");
        if (value.length > 2) {
            value = value.slice(0, 2) + "/" + value.slice(2);
        }
        setExpiry(value);
    };

    const handleInputBlur = () => {
        const [month, year] = expiry.split("/");
        const currentYear = new Date().getFullYear().toString().substr(-2);
        if (
            parseInt(month, 10) > 12 ||
            parseInt(month, 10) < 1 ||
            parseInt(year, 10) < Number(currentYear)
        ) {
            setDateError(true);
        } else {
            setDateError(false);
        }
    };

    const handleCvvInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value;
        value = value.replace(/[^\d]/g, "");
        if (value.length > 4 || value.length < 2) {
            setCvvError(true);
        } else {
            setCvvError(false);
        }
        setCvv(value);
    };

    const valiDated = () => {
        if (
            cardNumber === "" ||
            cvv === "" ||
            expiry === "" ||
            dateError ||
            cvvError ||
            cardNumberError
        ) {
            return false;
        }
        return true;
    };

    const handleCardTransfer = () => {
       if (orderDetails) {
            const [month, year] = expiry.split("/");
            let cardDetails = {
                cardCVV: Number(cvv),
                cardExpiryMonth: Number(month),
                cardExpiryYear: Number(year),
                cardNumber: cardNumber.replace(/\s+/g, ""),
            };
            setCardDetails(cardDetails);
            setCurrentSubScreen("CardPin");
        }
    }

    const cancel = () => {
        if (orderDetails && orderDetails?.data?.order?.callbackUrl) {
            handleCallbackUrl(orderDetails?.data?.order?.callbackUrl, orderDetails?.data?.order?.orderId, orderDetails?.data?.order?.orderReference);
        }
    }

    const confirm = () => {
        if (orderDetails && orderDetails?.data?.order?.callbackUrl) {
            handleCallbackUrl(orderDetails?.data?.order?.callbackUrl, orderDetails?.data?.order?.orderId, orderDetails?.data?.order?.orderReference);
        }
    }
    const handleClose = () => {
        setShowModal(false)
    }

    return (
        <FadeIn delay={100}>
            <OrderDetails>
                <Flex flexDirection="column">
                    <Typography fontSize="12px" color="n-grey3">
                        {orderDetails?.data?.order?.customerEmail}
                    </Typography>
                    <Typography fontSize="14px" fontWeight={500}>
                        {" "}
                        <span className="subtext">Pay</span> {formatCurrency(Number(orderDetails?.data?.order?.amount))}
                    </Typography>
                </Flex>
                <CloseModalNew onClick={() => setShowModal(true)} style={{ cursor: "pointer" }} />
                {showModal &&
                    <ConfirmModal confirm={confirm} handleClose={handleClose} showModal={showModal} />
                }
            </OrderDetails>


            {currentSubScreen === "CardInput" &&
                <FadeIn delay={100}>
                    <TransferDetails style={{ marginTop: "30px" }}>
                        <Typography color="n-dark" fontWeight={500}>
                            Enter your card information for payment
                        </Typography>

                        <FormWrapper>
                            <FormRow>
                                <CardNumberWrapper>
                                    <CardInputWrapper isError={cardNumberError}>
                                        <Typography fontSize='12px' color='n-grey5'>Card number</Typography>
                                        <CardNumberInput
                                            key="cardInput"
                                            type="tel"
                                            placeholder="0000 0000 0000 0000 0000"
                                            value={cardNumber}
                                            onChange={(e) => handleChange(e)}
                                            onBlur={handleCardInputBlur}

                                        />
                                    </CardInputWrapper>
                                    <CardInputLogo>
                                        {cardType === "Visa" ? (
                                            <VisaCard />
                                        ) : cardType === "Mastercard" ? (
                                            <MasterCard />
                                        ) : cardType === "Verve" ? <VerveCard /> : cardNumberError ? <ErrorCardIcon /> :
                                            <OtherCards />}
                                    </CardInputLogo>
                                </CardNumberWrapper>
                                {cardNumberError && (
                                    <ErrorText>Card number is invalid</ErrorText>
                                )}
                            </FormRow>

                            <FormRow style={{ marginBottom: "2px" }}>
                                <Flex style={{ gap: "10px" }}>
                                    <Box style={{ width: "100%" }}>
                                        <CardInputWrapper isError={dateError} style={{ marginBottom: "10px" }}>
                                            <Typography fontSize='12px' color='n-grey5'>Expiry date</Typography>
                                            <CardNumberInput
                                                key="expiry"
                                                type="text"
                                                name='expiryFocused'
                                                placeholder="MM/YY"
                                                maxLength={5}
                                                value={expiry}
                                                onChange={handleInputChange}
                                                onBlur={handleInputBlur}

                                            />
                                        </CardInputWrapper>
                                        {dateError && (
                                            <ErrorText>Invalid date</ErrorText>
                                        )}
                                    </Box>
                                    <Box style={{ width: "100%" }}>
                                        <CardNumberWrapper>
                                            <CardInputWrapper isError={cvvError}>
                                                <Typography fontSize='12px' color='n-grey5'>CVV</Typography>
                                                <CardNumberInput
                                                    key="cvv"
                                                    type="number"
                                                    name='cvvFocused'
                                                    placeholder="123"
                                                    value={cvv}
                                                    maxLength={4}
                                                    onChange={handleCvvInputChange}
                                                />
                                            </CardInputWrapper>
                                            <CardInputLogo>
                                                <Box className='help-container'>
                                                    <Typography fontSize='14px' color='n-grey3' style={{ cursor: "pointer" }}>Help?</Typography>
                                                    <CvvTooltip className='help-text'>
                                                        <CvvHelp />
                                                        <Typography fontSize='12px' color='n-light'>
                                                            Your CVV is the 3-digit number located on the back of your credit/debit card on the right side of the white  signature strip
                                                        </Typography>
                                                    </CvvTooltip>
                                                </Box>
                                            </CardInputLogo>
                                        </CardNumberWrapper>
                                        {cvvError && (
                                            <ErrorText>Invalid cvv</ErrorText>
                                        )}
                                    </Box>
                                </Flex>
                            </FormRow>

                            {valiDated() ?
                                <Button
                                    onClick={() => handleCardTransfer()}
                                    textAlign="center"
                                    mt="20px"
                                    bg="#FFCC00"
                                    color="#121212"
                                    variant="large"
                                    width="100%"
                                    height="50px"
                                    className="cursor-pointer"
                                >
                                    <ButtonText>Pay {formatCurrency(Number(orderDetails?.data?.order?.amount))}</ButtonText>
                                </Button>
                                :
                                <Button
                                    disabled={true}
                                    opacity="1"
                                    mt="20px"
                                    bg="#FFF5CC"
                                    color="#ccc"
                                    variant="large"
                                    width="100%"
                                    height="50px"
                                    className="cursor-pointer"
                                >
                                    <Typography color='n-grey3'>Pay {formatCurrency(Number(orderDetails?.data?.order?.amount))}</Typography>
                                </Button>
                            }
                          
                        </FormWrapper>
                    </TransferDetails>

                    <MobileCardDetails>
                        <Flex justifyContent="space-between" alignItems="center">
                            <Typography color="n-grey5" fontWeight={600}>
                                Pay by Card
                            </Typography>
                            <AtmCardActiveIcon />
                        </Flex>

                        <MobileFormWrapper>
                            <FormRow>
                                <CardNumberWrapper>
                                    <MobileCardInputWrapper isError={cardNumberError}>
                                        <Typography fontSize='12px' color='n-grey5'>Card number</Typography>
                                        <MobileCardNumberInput
                                            key="cardInput"
                                            type="tel"
                                            placeholder="0000 0000 0000 0000 0000"
                                            value={cardNumber}
                                            onChange={(e) => handleChange(e)}
                                            onBlur={handleCardInputBlur}

                                        />
                                    </MobileCardInputWrapper>
                                    <CardInputLogo>
                                        {cardType === "Visa" ? (
                                            <VisaCard />
                                        ) : cardType === "Mastercard" ? (
                                            <MasterCard />
                                        ) : cardType === "Verve" ? <VerveCard /> : cardNumberError ? <ErrorCardIcon /> :
                                            <OtherCards />}
                                    </CardInputLogo>
                                </CardNumberWrapper>
                                {cardNumberError && (
                                    <ErrorText>Card number is invalid</ErrorText>
                                )}
                            </FormRow>

                            <FormRow style={{ marginBottom: "2px" }}>
                                <Flex style={{ gap: "10px" }}>
                                    <Box>
                                        <MobileCardInputWrapper isError={dateError} style={{ marginBottom: "10px" }}>
                                            <Typography fontSize='12px' color='n-grey5'>Expiry date</Typography>
                                            <MobileCardNumberInput
                                                key="expiry"
                                                type="text"
                                                name='expiryFocused'
                                                placeholder="MM/YY"
                                                maxLength={5}
                                                value={expiry}
                                                onChange={handleInputChange}
                                                onBlur={handleInputBlur}

                                            />
                                        </MobileCardInputWrapper>
                                        {dateError && (
                                            <ErrorText>Invalid date</ErrorText>
                                        )}
                                    </Box>
                                    <Box>
                                        <CardNumberWrapper>
                                            <MobileCardInputWrapper isError={cvvError}>
                                                <Typography fontSize='12px' color='n-grey5'>CVV</Typography>
                                                <MobileCardNumberInput
                                                    key="cvv"
                                                    type="number"
                                                    name='cvvFocused'
                                                    placeholder="123"
                                                    value={cvv}
                                                    maxLength={4}
                                                    onChange={handleCvvInputChange}
                                                />
                                            </MobileCardInputWrapper>
                                            <CardInputLogo>
                                                <Box className='help-container'>
                                                    <Typography fontSize='14px' color='n-grey3' style={{ cursor: "pointer" }}>Help?</Typography>
                                                    <CvvTooltip className='help-text'>
                                                        <CvvHelp />
                                                        <Typography fontSize='12px' color='n-light'>
                                                            Your CVV is the 3-digit number located on the back of your credit/debit card on the right side of the white  signature strip
                                                        </Typography>
                                                    </CvvTooltip>
                                                </Box>
                                            </CardInputLogo>
                                        </CardNumberWrapper>
                                        {cvvError && (
                                            <ErrorText>Invalid cvv</ErrorText>
                                        )}
                                    </Box>
                                </Flex>
                            </FormRow>

                            {valiDated() ?
                                <Button
                                    onClick={() => handleCardTransfer()}
                                    textAlign="center"
                                    mt="20px"
                                    bg="#FFCC00"
                                    color="#121212"
                                    variant="large"
                                    width="100%"
                                    height="60px"
                                    className="cursor-pointer"
                                >
                                    <ButtonText>Pay {formatCurrency(Number(orderDetails?.data?.order?.amount))}</ButtonText>
                                </Button>
                                :
                                <Button
                                    disabled={true}
                                    opacity="1"
                                    mt="20px"
                                    bg="#FFF5CC"
                                    color="#ccc"
                                    variant="large"
                                    width="100%"
                                    height="60px"
                                    className="cursor-pointer"
                                >
                                    <Typography color='n-grey3'>Pay {formatCurrency(Number(orderDetails?.data?.order?.amount))}</Typography>
                                </Button>
                            }
                          
                        </MobileFormWrapper>
                    </MobileCardDetails>
                    <MobileControl>
                        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" width="100%" mt="40px">
                            <Button onClick={() => changePaymentMethod()} mb="20px" bg="#FFFFFF" color="#121212" variant="large" width="80%" height="60px" className="cursor-pointer change-btn">
                                <ButtonText>Change payment method</ButtonText>
                            </Button>
                            <Typography onClick={() => cancel()} className='cursor-pointer' color='n-grey4' fontWeight={600}>Cancel payment</Typography>
                        </Box>
                    </MobileControl>
                </FadeIn>
            }

            {currentSubScreen === "CardPin" && cardDetails && orderDetails?.data?.order?.orderReference && <CardPin saveCard={false} setOtpMessage={setOtpMessage} setCurrentSubScreen={setCurrentSubScreen} orderReference={orderDetails?.data?.order?.orderReference} cardDetails={cardDetails} />}
            {currentSubScreen === "CardOtp" && cardDetails && orderDetails?.data?.order?.orderReference && <CardOtp saveCard={false} onMoveToNewScreen={onMoveToNewScreen} otpMessage={otpMessage} setCurrentSubScreen={setCurrentSubScreen} orderReference={orderDetails?.data?.order?.orderReference} />}
        </FadeIn>
    )
}
