import { GetHelpWrapper, MobileControl, MobileTransferDetails, TransferDetails } from '../style';
import { Typography } from 'kudi-component-library';
import { Button, Flex } from 'rebass';
import { ButtonText } from '../../styles';
import InfoIcon from '../../../assets/info-icon';
import RetryIcon from '../../../assets/retry';
import { ScreenTypes } from '../../../utils';

export default function NombaQrFailed({
  setCurrentSubScreen,
  changePaymentMethod,
}: {
  setCurrentSubScreen: React.Dispatch<React.SetStateAction<ScreenTypes>>;
  changePaymentMethod: () => void;
}) {
  return (
    <>
      <TransferDetails>
        <GetHelpWrapper style={{ paddingBottom: '70px' }}>
          <InfoIcon />
          <Typography color="dark" fontSize="16px" fontWeight={700}>
            Unable to generate QR
          </Typography>
          <Typography color="dark" fontSize="14px" lineHeight="18px">
            Sorry, we are unable to generate <br /> your QR at the moment, kindly try <br /> again.
          </Typography>
        </GetHelpWrapper>

        <Flex width="100%" className="cursor-pointer" alignItems="center" mt="30px" justifyContent="center">
          <Button
            onClick={() => setCurrentSubScreen('NombaQR')}
            textAlign="center"
            bg="#FFCC00"
            color="#121212"
            variant="large"
            width="100%"
            height="50px"
            className="cursor-pointer"
            style={{ borderRadius: '8px' }}
          >
            <Flex justifyContent="center" alignItems="center" style={{ gap: '10px' }}>
              <RetryIcon /> <ButtonText style={{ whiteSpace: 'nowrap' }}>Try again</ButtonText>
            </Flex>
          </Button>
        </Flex>
      </TransferDetails>

      <MobileTransferDetails>
        <GetHelpWrapper style={{ paddingBottom: '70px' }}>
          <InfoIcon />
          <Typography color="dark" fontSize="16px" fontWeight={700}>
            Unable to generate QR
          </Typography>
          <Typography color="dark" fontSize="14px" lineHeight="18px">
            Sorry, we are unable to generate <br /> your QR at the moment, kindly try <br /> again.
          </Typography>
        </GetHelpWrapper>

        <Flex width="100%" className="cursor-pointer" alignItems="center" mt="30px" justifyContent="center">
          <Button
            onClick={() => setCurrentSubScreen('NombaQR')}
            textAlign="center"
            bg="#FFCC00"
            color="#121212"
            variant="large"
            width="100%"
            height="50px"
            className="cursor-pointer"
            style={{ borderRadius: '8px' }}
          >
            <Flex justifyContent="center" alignItems="center" style={{ gap: '10px' }}>
              <RetryIcon /> <ButtonText style={{ whiteSpace: 'nowrap' }}>Try again</ButtonText>
            </Flex>
          </Button>
        </Flex>

        <MobileControl style={{ marginTop: '30px' }}>
          <Button
            onClick={() => changePaymentMethod()}
            bg="#FFFFFF"
            color="#121212"
            variant="large"
            width="100%"
            height="60px"
            className="cursor-pointer change-btn"
          >
            <ButtonText>Change payment method</ButtonText>
          </Button>
        </MobileControl>
      </MobileTransferDetails>
    </>
  );
}
