import { lazy } from 'react';

const CloseModal = lazy(() => import('./closemodal'));
const CompanyLogo = lazy(() => import('./logo'));
const CardPayment = lazy(() => import('./card'));
const BankPayment = lazy(() => import('./bank'));
const ChevronRight = lazy(() => import('./chevronright'));
const FooterLogo = lazy(() => import('./footerlogo'));
const FooterLogoDark = lazy(() => import('./footer-logo-dark'));
const MasterCard = lazy(() => import('./mastercard'));
const VisaCard = lazy(() => import('./visa'));
const OtherCards = lazy(() => import('./othercards'));
const SmartPhoneLogo = lazy(() => import('./smartphone'));
const Spinner = lazy(() => import('./spinner'));
const Alert = lazy(() => import('./alert'));
const Success = lazy(() => import('./success'));
const Rotate = lazy(() => import('./rotate'));
const CircleLogo = lazy(() => import('./circlelogo'));
const VerveCard = lazy(() => import('./vervecard'));
const UssdIcon = lazy(() => import('./ussd'));
const GiftIcon = lazy(() => import('./gift'));
const QrIcon = lazy(() => import('./qr-scan'));
const RightArrow = lazy(() => import('./right-arrow'));
const Star = lazy(() => import('./star'));

export {
  CloseModal,
  CompanyLogo,
  CardPayment,
  BankPayment,
  ChevronRight,
  FooterLogo,
  MasterCard,
  VisaCard,
  VerveCard,
  OtherCards,
  SmartPhoneLogo,
  Spinner,
  Alert,
  Success,
  Rotate,
  CircleLogo,
  FooterLogoDark,
  UssdIcon,
  GiftIcon,
  QrIcon,
  RightArrow,
  Star,
};
