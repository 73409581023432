import { NTheme } from "kudi-component-library";
import { useEffect, Suspense } from "react";
import { Flex } from "rebass";
import { ThemeProvider } from "styled-components";
import Modal from "../../Modal";
import { formatCurrency, handleCallbackUrl } from "../../../utils";
import { AppBody, AppHeaderText, InfoText } from "../../styles";
import { ErrorMessage } from "../../v2/style";
import SuccessNewIcon from "../../../assets/success-new";
import { IOrderDetails } from "../../../redux/slice";

export default function SuccessResponse({
  saveCard,
  orderDetails,
}: {
  saveCard: boolean;
  orderDetails: IOrderDetails | null;
}) {
  const handleClose = () => {
    if (orderDetails && orderDetails?.data?.order?.callbackUrl) {
      handleCallbackUrl(
        orderDetails?.data?.order?.callbackUrl,
        orderDetails?.data?.order?.orderReference,
        orderDetails?.data?.order?.orderReference
      );
    } else {
      window.close();
    }
  };

  useEffect(() => {
    if (saveCard) return;
    setTimeout(() => {
      if (orderDetails && orderDetails?.data?.order?.callbackUrl) {
        handleCallbackUrl(
          orderDetails?.data?.order?.callbackUrl,
          orderDetails?.data?.order?.orderReference,
          orderDetails?.data?.order?.orderReference
        );
      }
    }, 5000);
  }, [orderDetails, saveCard]);

  return (
    <ThemeProvider theme={NTheme}>
      <AppBody>
        <Flex justifyContent={"center"} alignItems={"center"}>
          <Suspense fallback={<h1>Loading...</h1>}>
            <Modal showModal={true} onClose={handleClose}>
              <Flex
                bg="#FFFFFF"
                height="100%"
                style={{ borderRadius: "16px" }}
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <SuccessNewIcon />
                <AppHeaderText mt="10px">
                  Your Payment has been <br />
                  confirmed successfully
                </AppHeaderText>

                <ErrorMessage>
                  <InfoText fontSize="12px" textAlign="center">
                    Your payment of{" "}
                    <span style={{ fontWeight: 700 }}>
                      {formatCurrency(
                        Number(orderDetails?.data?.order?.amount)
                      )}
                    </span>{" "}
                    to <span style={{ fontWeight: 700 }}>Nomba Test</span> has
                    been confirmed. You will now be redirected to your
                    merchant's site. Thank you.
                  </InfoText>
                </ErrorMessage>
              </Flex>
            </Modal>
          </Suspense>
        </Flex>
      </AppBody>
    </ThemeProvider>
  );
}
