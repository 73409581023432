import { ReactComponent as NetworkIconIcon } from "./network.svg";

const NetworkIcon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
        title?: string | undefined;
    }
> = (props) => <NetworkIconIcon {...props} />;

export default NetworkIcon;

