import { GetHelpWrapper, Link, MobileTransferDetails, TransferDetails } from '../style';
import HelpIcon from '../../../assets/help';
import { Typography } from 'kudi-component-library';
import { Button, Flex } from 'rebass';
import { ButtonText } from '../../styles';
import { ScreenTypes, handleCallbackUrl } from '../../../utils';
import ConfirmModal from '../../ConfirmModal';
import { useState } from 'react';
import { Orderdetails } from '../../../redux/slice';

export default function GetHelp({
  orderDetails,
  setCurrentSubScreen,
}: {
  orderDetails: Orderdetails | null;
  setCurrentSubScreen: React.Dispatch<React.SetStateAction<ScreenTypes>>;
}) {
  const [showModal, setShowModal] = useState<boolean>(false);
  const handleClose = () => {
    setShowModal(false);
  };
  const confirm = () => {
    if (orderDetails) {
      handleCallbackUrl(
        orderDetails?.data?.order?.callbackUrl,
        orderDetails?.data?.order?.orderId,
        orderDetails?.data?.order?.orderReference
      );
    }
  };
  return (
    <>
      <TransferDetails>
        <GetHelpWrapper>
          <HelpIcon />
          <Typography color="dark" fontSize="14px" lineHeight="18px">
            Note that this transaction will be completed automatically as soon as we confirm your payment.
          </Typography>
        </GetHelpWrapper>

        <Flex justifyContent="center" textAlign="center" mt="24px">
          <Typography color="dark" fontSize="14px" lineHeight="18px">
            Need further help? Reach out to us on <br />
            <Link href="mailto:support@nomba.com"> support@nomba.com</Link>
          </Typography>
        </Flex>

        <Flex
          style={{ gap: '16px' }}
          width="100%"
          className="cursor-pointer"
          alignItems="center"
          mt="24px"
          justifyContent="space-between"
        >
          <Button
            onClick={() => setCurrentSubScreen('ConfirmTransfer')}
            textAlign="center"
            bg="#FFCC00"
            color="#121212"
            variant="large"
            width="100%"
            height="50px"
            className="cursor-pointer"
            style={{ borderRadius: '8px' }}
          >
            <ButtonText style={{ whiteSpace: 'nowrap' }}>Keep waiting</ButtonText>
          </Button>
          <Button
            onClick={() => setShowModal(true)}
            textAlign="center"
            bg="#FFFFFF"
            color="#121212"
            variant="large"
            width="100%"
            height="50px"
            className="cursor-pointer"
            style={{ border: '1px solid #E6E6E6', borderRadius: '8px' }}
          >
            <ButtonText>Close checkout</ButtonText>
          </Button>
        </Flex>

        {showModal && <ConfirmModal confirm={confirm} showModal={showModal} handleClose={handleClose} />}
      </TransferDetails>

      <MobileTransferDetails>
        <GetHelpWrapper>
          <HelpIcon />
          <Typography color="dark" fontSize="14px" lineHeight="18px">
            Note that this transaction will be completed automatically as soon as we confirm your payment.
          </Typography>
        </GetHelpWrapper>

        <Flex justifyContent="center" textAlign="center" mt="24px">
          <Typography color="dark" fontSize="14px" lineHeight="18px">
            Need further help? Reach out to us on <br />
            <Link href="mailto:support@nomba.com"> support@nomba.com</Link>
          </Typography>
        </Flex>

        <Flex
          style={{ gap: '16px' }}
          width="100%"
          className="cursor-pointer"
          alignItems="center"
          mt="24px"
          justifyContent="space-between"
        >
          <Button
            onClick={() => setCurrentSubScreen('ConfirmTransfer')}
            textAlign="center"
            bg="#FFCC00"
            color="#121212"
            variant="large"
            width="100%"
            height="50px"
            className="cursor-pointer"
            style={{ borderRadius: '8px' }}
          >
            <ButtonText style={{ whiteSpace: 'nowrap' }}>Keep waiting</ButtonText>
          </Button>
          <Button
            onClick={() => setShowModal(true)}
            textAlign="center"
            bg="#FFFFFF"
            color="#121212"
            variant="large"
            width="100%"
            height="50px"
            className="cursor-pointer"
            style={{ border: '1px solid #E6E6E6', borderRadius: '8px' }}
          >
            <ButtonText>Close checkout</ButtonText>
          </Button>
        </Flex>
        {showModal && <ConfirmModal confirm={confirm} showModal={showModal} handleClose={handleClose} />}
      </MobileTransferDetails>
    </>
  );
}
