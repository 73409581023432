import React, { Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import { useParams } from 'react-router-dom';
import { fetchPaymentLink } from '../../redux/action';
import Loader from '../../components/Loader';
import PageNav from '../components/PageNav';
import { Box, Flex } from 'rebass';
import styled from 'styled-components';
import PaymentForm from './PaymentLinkForm';
import PageFooter from '../components/PageFooter';
import MainModal from '../../components/v2/Main';
import ErrorScreen from './ErrorScreen';

const PaymentLinkBody = styled(Box)`
  padding: 10px;
  flex-direction: column;
  gap: 16px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0.3) 100%),
    linear-gradient(240deg, #ffe9d6 -9.62%, #fff 100%);
  position: relative;
  height: 90vh;

  @media ${({ theme }) => theme.media.lg} {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 16px;
    padding: 24px 24px 64px 24px;
  }
`;

const PaymentLinkCard = styled(Box)`
  width: 100%;
  padding: 24px 26px;
  border-radius: 24px;
  background: #fff;

  @media ${({ theme }) => theme.media.lg} {
    width: 588px;
    height: 100%;
    padding: 32px 34px;
  }
`;

const PaymentLinkImage = styled.img`
  width: 100%;
  height: 25%;
  object-fit: cover;
  border-radius: 16px;

  @media ${({ theme }) => theme.media.lg} {
    width: 588px;
    height: 100%;
  }
`;

const PaymentLink: React.FC = () => {
  const { id } = useParams();
  const [openModal, setOpenModal] = useState(false);
  const [customId, setCustomId] = useState('');
  const dispatch = useDispatch<AppDispatch>();
  const { isLoading, paymentLinkResponse } = useSelector((state: RootState) => state.order);

  useEffect(() => {
    if (id) {
      dispatch(fetchPaymentLink({ orderId: id }));
    }
  }, [id]);

  return (
    <Suspense fallback={<Loader />}>
      {isLoading ? (
        <Flex
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
          mt="auto"
          mb="auto"
          style={{ height: '100%' }}
        >
          <Loader />
        </Flex>
      ) : (
        <>
          {paymentLinkResponse?.data?.merchantDetails && (
            <PageNav
              companyName={paymentLinkResponse?.data?.merchantDetails?.businessName}
              email={paymentLinkResponse?.data?.merchantDetails?.email}
            />
          )}
          <PaymentLinkBody>
            {paymentLinkResponse?.data?.imageUrl && (
              <PaymentLinkImage src={paymentLinkResponse?.data?.imageUrl} alt="payment link display" />
            )}
            {!paymentLinkResponse ||
            paymentLinkResponse.code.includes('4') ||
            paymentLinkResponse.code.includes('5') ? (
              <ErrorScreen error={paymentLinkResponse?.description} />
            ) : (
              <PaymentLinkCard>
                <PaymentForm
                  paymentLinkResponse={paymentLinkResponse}
                  setOpenModal={setOpenModal}
                  setCustomId={setCustomId}
                />
              </PaymentLinkCard>
            )}

            <PageFooter />
          </PaymentLinkBody>
        </>
      )}

      {openModal && (
        <MainModal
          showModal={openModal}
          setShowModal={() => {
            setOpenModal(false);
            window.location.reload();
          }}
          customId={customId}
          redirectUrl={paymentLinkResponse?.data?.redirectUrl}
        />
      )}
    </Suspense>
  );
};

export default PaymentLink;
