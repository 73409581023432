import { ReactComponent as VerveIcon } from "./verve.svg";

const VerveCard: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
        title?: string | undefined;
    }
> = (props) => <VerveIcon {...props} />;

export default VerveCard;

