import React from "react";
import { Flex } from "rebass";
import styled from "styled-components";
import { FooterLogoDark } from "../../assets";
import { Typography } from "kudi-component-library";

const FooterContainer = styled(Flex)`
  position: fixed;
  bottom: 0px;
  width: 100%;
  height: 40px;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.3) 0%,
      rgba(255, 255, 255, 0.3) 100%
    ),
    linear-gradient(240deg, #ffe9d6 -9.62%, #fff 100%);
`;

const PageFooter: React.FC = () => {
  return (
    <FooterContainer justifyContent="center" alignItems="center">
      <Typography variant="n-text1" style={{ marginRight: "10px" }}>
        Secured by
      </Typography>{" "}
      <FooterLogoDark />
    </FooterContainer>
  );
};

export default PageFooter;
