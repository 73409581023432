import { Typography } from 'kudi-component-library';
import { useState, useEffect } from 'react';

export const CountdownTimer = ({
  minutes,
  seconds,
  cb,
  hidden,
  color,
  fontSize,
  setElapsedTime,
  setIsHalftime,
}: {
  minutes: number;
  seconds: number;
  cb: () => void;
  hidden?: boolean;
  color?: string;
  fontSize?: string;
  setElapsedTime?: React.Dispatch<React.SetStateAction<number>>;
  setIsHalftime?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [[mins, secs], setTime] = useState([0, 0]);

  const tick = () => {
    if (mins === 0 && secs === 0) {
      setTime([0, 0]);
      cb();
    } else if (secs === 0) {
      setTime([mins - 1, 59]);
    } else {
      setTime([mins, secs - 1]);
    }

    const totalElapsedSeconds = (minutes - mins) * 60 + (seconds - secs);
    setElapsedTime && setElapsedTime(totalElapsedSeconds);

    if (mins <= 5 && setIsHalftime) {
      setIsHalftime(true);
    }
  };

  useEffect(() => {
    const timerId = setInterval(() => tick(), 1000);
    return () => clearInterval(timerId);
  });

  useEffect(() => {
    setTime([minutes, seconds]);
    setElapsedTime && setElapsedTime(1);
  }, [minutes, seconds, setElapsedTime]);

  return hidden ? null : (
    <Typography style={{ color: color }} fontSize={fontSize || '12px'} fontWeight={700}>
      {mins > 0 || secs > 0 ? `${mins}:${secs < 10 ? `0${secs}` : secs}` : ''}
    </Typography>
  );
};
