import { useEffect, useState } from 'react';
import { IUssdCodeResponse, KtaOrderResponse, Orderdetails } from '../../../redux/slice';
import { ScreenTypes, formatCurrency } from '../../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store';
import { errorResponse, successResponse } from '../../../utils/toast';
import FadeIn from 'react-fade-in/lib/FadeIn';
import {
  AccountNumberWrapper,
  BankInfoWrapper,
  ClockIconWrapper,
  CopyBox,
  MobileControl,
  MobileTransferDetails,
  Spinner,
  TransferDetails,
} from '../style';
import { ButtonText, InfoText } from '../../styles';
import { Box, Button, Flex } from 'rebass';
import { IconsEnum, NIcons, Typography, NTheme as theme } from 'kudi-component-library';
import MessageIcon from '../../../assets/message';
import { CountdownTimer } from '../../../utils/Countdown';
import ProgressBar from '../../ProgressBar';
import ConfirmTransfer from './ConfirmTransfer';
import GetHelp from './GetHelp';
import { confirmBankTransfer } from '../../../redux/action';

interface UssdProps {
  orderDetails: Orderdetails | null;
  ktaResponse: KtaOrderResponse | null;
  onMoveToNewScreen: (newscreen: ScreenTypes, error: string, saveCard: boolean) => void;
  changePaymentMethod: () => void;
  customId?: string;
  setShowCustomModal?: () => void;
  ussdBankId: string;
  bankName: string;
  bankCode: string;
  processor: string;
  processorCodeName: string;
  ussdCodeFormat: string;
  enabled: boolean;
  promptMessage: string;
  orderReference?: string;
  ussdCodeResponse: IUssdCodeResponse | null;
}

const Ussd = ({
  orderDetails,
  ktaResponse,
  changePaymentMethod,
  onMoveToNewScreen,
  orderReference,
  ussdCodeResponse,
}: UssdProps) => {
  const [timer, setTimer] = useState<number>(30);
  const [elapsedTime, setElapsedTime] = useState<number>(1);
  const [currentSubScreen, setCurrentSubScreen] = useState<ScreenTypes>('USSD2');
  const [confirmFailed, setConfirmFailed] = useState<boolean>(false);

  const dispatch = useDispatch<AppDispatch>();

  const { confirmingPayment, confirmResponse } = useSelector((state: RootState) => state.order);

  const calculateProgressBarWidth = () => {
    const totalDurationInSeconds = timer * 60;
    const percentageRemaining = ((totalDurationInSeconds - elapsedTime) / totalDurationInSeconds) * 100;
    return percentageRemaining < 1 ? '0%' : `${percentageRemaining}%`;
  };

  const handleCopy = (val: string | number, copyType: string) => {
    navigator.clipboard
      .writeText(val.toString())
      .then(function () {
        successResponse(`${copyType} copied to clipboard`);
      })
      .catch(() => errorResponse('Unable to copy'));
  };

  const moveToSubScreen = (screen: ScreenTypes) => {
    setCurrentSubScreen(screen);
  };

  const ussdCode = ussdCodeResponse?.data?.ussdCode;

  useEffect(() => {
    if (elapsedTime === 420 && orderDetails) {
      dispatch(confirmBankTransfer({ orderReference: orderDetails?.data?.order?.orderId }));
    }
  }, [elapsedTime, orderDetails, dispatch]);

  useEffect(() => {
    // retry confirmation of payment every 60 seconds
    const interval = setInterval(() => {
      orderDetails?.data?.order?.orderId &&
        dispatch(confirmBankTransfer({ orderReference: orderDetails?.data?.order?.orderId }));
    }, 60000);
    return () => clearInterval(interval);
  }, [dispatch, orderDetails]);

  useEffect(() => {
    if (confirmResponse === null) {
      return;
    }
    if (confirmResponse?.data?.status) {
      onMoveToNewScreen('SuccessResponse', '', false);
      return;
    }
    if (!confirmResponse?.data?.status && timer === 0) {
      setConfirmFailed(true);
    }
  }, [confirmResponse, timer, onMoveToNewScreen]);

  return (
    <FadeIn delay={100}>
      {timer !== 0 && currentSubScreen === 'USSD2' && (
        <>
          <TransferDetails
            style={{
              height: confirmingPayment ? '500px' : '',
              overflowY: confirmingPayment ? 'scroll' : 'hidden',
            }}
          >
            {confirmingPayment && (
              <BankInfoWrapper style={{ padding: '10px', marginTop: '0' }} bg={theme.nColors['n-grey1']}>
                <InfoText>
                  This page has been inactive for a while. We are trying to confirm if you already sent it.
                </InfoText>
                <Flex width="40%" justifyContent="flex-end">
                  <Spinner borderbottomcolor="=FFCC00" color="#FFFFFF" />
                </Flex>
              </BankInfoWrapper>
            )}

            {confirmFailed && !confirmingPayment && (
              <BankInfoWrapper style={{ padding: '10px', marginTop: '0' }} bg={theme.nColors['n-yellow1']}>
                <InfoText>Sorry, no transaction confirmed yet. Kindly transfer and confirm it.</InfoText>
                <Flex width="40%" justifyContent="flex-end">
                  <MessageIcon />
                </Flex>
              </BankInfoWrapper>
            )}

            {!confirmFailed && !confirmingPayment && (
              <Typography style={{ textAlign: 'center' }} color="n-dark" fontWeight={500}>
                Dial the USSD code below on your mobile phone to complete the payment
              </Typography>
            )}

            <AccountNumberWrapper>
              <Flex flexDirection="column">
                <Typography fontSize="14px" fontWeight={600}>
                  {ussdCode ?? ''}
                </Typography>
              </Flex>
              <CopyBox onClick={() => ktaResponse?.data?.accountNumber && handleCopy(ussdCode ?? '', 'USSD code')}>
                <Typography fontSize="12px">COPY</Typography>
              </CopyBox>
            </AccountNumberWrapper>

            <BankInfoWrapper bg="#F2F6FF">
              <Flex flexDirection="column">
                <Typography fontSize="12px" color="n-grey4">
                  Amount
                </Typography>
                <Typography fontSize="14px" lineHeight="18px" fontWeight={600}>
                  {formatCurrency(Number(orderDetails?.data?.order?.amount))}
                </Typography>
              </Flex>
            </BankInfoWrapper>

            <Flex justifyContent="center" alignItems="center" width="50%" style={{ gap: '10px' }}>
              <Typography style={{ whiteSpace: 'nowrap' }} color="n-grey4" fontSize="12px" fontWeight={500}>
                Expires in
              </Typography>
              <CountdownTimer
                {...{
                  minutes: timer,
                  seconds: 0,
                  cb: () => setTimer(0),
                  setElapsedTime: setElapsedTime,
                  color: '#0E9C00',
                }}
              />
              <ClockIconWrapper>
                <NIcons icon={IconsEnum.OUTLINE_CLOCK} width="100%" height="100%" />
              </ClockIconWrapper>
              <ProgressBar width={timer === 0 ? '0%' : calculateProgressBarWidth()} />
            </Flex>

            <Button
              onClick={() => moveToSubScreen('ConfirmTransfer')}
              textAlign="center"
              mt="20px"
              bg="#FFCC00"
              color="#121212"
              variant="large"
              width="100%"
              height="50px"
              className="cursor-pointer"
            >
              <ButtonText>I have sent the money</ButtonText>
            </Button>
          </TransferDetails>

          <MobileTransferDetails>
            {confirmingPayment && (
              <BankInfoWrapper style={{ padding: '10px', marginTop: '0' }} bg={theme.nColors['n-grey1']}>
                <InfoText>
                  This page has been inactive for a while. We are trying to confirm if you already sent it.
                </InfoText>
                <Flex width="40%" justifyContent="flex-end">
                  <Spinner borderbottomcolor="#FFCC00" color="#FFFFFF" />
                </Flex>
              </BankInfoWrapper>
            )}

            {confirmFailed && !confirmingPayment && (
              <BankInfoWrapper style={{ padding: '10px', marginTop: '0' }} bg={theme.nColors['n-yellow1']}>
                <InfoText>Sorry, no transaction confirmed yet. kindly transfer and confirm it.</InfoText>
                <Flex width="40%" justifyContent="flex-end">
                  <MessageIcon />
                </Flex>
              </BankInfoWrapper>
            )}

            {!confirmFailed && !confirmingPayment && (
              <Typography style={{ textAlign: 'center' }} color="n-dark" fontWeight={500}>
                Dial the USSD code below on your mobile phone to complete the payment
              </Typography>
            )}

            <AccountNumberWrapper>
              <Flex flexDirection="column">
                <Typography fontSize="14px" fontWeight={600}>
                  {ussdCode ?? ''}
                </Typography>
              </Flex>
              <CopyBox onClick={() => ktaResponse?.data?.accountNumber && handleCopy(ussdCode ?? '', 'USSD code')}>
                <Typography fontSize="12px">COPY</Typography>
              </CopyBox>
            </AccountNumberWrapper>
            <BankInfoWrapper bg="#F2F6FF">
              <Flex flexDirection="column">
                <Typography fontSize="12px" color="n-grey4">
                  Amount
                </Typography>
                <Typography fontSize="14px" lineHeight="18px" fontWeight={600}>
                  {formatCurrency(Number(orderDetails?.data?.order?.amount))}
                </Typography>
              </Flex>
            </BankInfoWrapper>

            <Box display="flex" justifyContent="center" alignItems="center">
              <Box display="flex" justifyContent="center" alignItems="center" width="50%" style={{ gap: '10px' }}>
                <Typography
                  style={{ whiteSpace: 'nowrap', textAlign: 'center' }}
                  color="n-grey4"
                  fontSize="12px"
                  fontWeight={500}
                >
                  Expires in
                </Typography>
                <CountdownTimer
                  {...{
                    minutes: timer,
                    seconds: 0,
                    cb: () => setTimer(0),
                    setElapsedTime: setElapsedTime,
                    color: '#0E9C00',
                  }}
                />
                <ClockIconWrapper>
                  <NIcons icon={IconsEnum.OUTLINE_CLOCK} width="100%" height="100%" />
                </ClockIconWrapper>
                <ProgressBar width={timer === 0 ? '0%' : calculateProgressBarWidth()} />
              </Box>
            </Box>

            <Button
              onClick={() => moveToSubScreen('ConfirmTransfer')}
              textAlign="center"
              mt="20px"
              bg="#FFCC00"
              color="#121212"
              variant="large"
              width="100%"
              height="60px"
              className="cursor-pointer"
            >
              <ButtonText>I have sent the money</ButtonText>
            </Button>

            <MobileControl onClick={() => changePaymentMethod()} style={{ marginTop: '20px' }}>
              <Button
                bg="#FFFFFF"
                color="#121212"
                variant="large"
                width="100%"
                height="60px"
                className="cursor-pointer change-btn"
              >
                <ButtonText>Change payment method</ButtonText>
              </Button>
            </MobileControl>
          </MobileTransferDetails>
        </>
      )}

      {currentSubScreen === 'ConfirmTransfer' && (
        <ConfirmTransfer
          orderReference={orderDetails?.data?.order?.orderId}
          setCurrentSubScreen={setCurrentSubScreen}
          onMoveToNewScreen={onMoveToNewScreen}
        />
      )}

      {currentSubScreen === 'GetHelp' && (
        <GetHelp orderDetails={orderDetails} setCurrentSubScreen={setCurrentSubScreen} />
      )}
    </FadeIn>
  );
};

export default Ussd;
