import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { NTheme } from 'kudi-component-library';
import { FlagsmithProvider } from 'flagsmith/react';
import { ThemeProvider } from 'styled-components';
import flagsmith from 'flagsmith';
import 'react-circular-progressbar/dist/styles.css';
import Home from './components/Home';
import PaymentCallback from './components/PaymentCallback';
import { config } from './utils/config';
import Landing from './components/v2/Landing';
import Sandbox from './components/sandbox/Sandbox';
import PaymentLink from './pages/PaymentLink/PaymentLink';
import SubscriptionPage from './pages/Subscription/SubscriptionPage';
import MpgsPage from './components/v2/MpgsPage';

function App() {
  return (
    <FlagsmithProvider options={{ environmentID: config.flagsmith_api_key }} flagsmith={flagsmith}>
      <BrowserRouter>
        <ThemeProvider theme={NTheme}>
          <Routes>
            {process.env.REACT_APP_CHECKOUT_VERSION === '1.0' ? (
              <Route path="/checkout/:id" element={<Home />}></Route>
            ) : (
              <Route path="/pay/:id" element={<Landing />}></Route>
            )}
            <Route path="/callback/:id" element={<PaymentCallback />}></Route>
            <Route path="/sandbox/:id" element={<Sandbox />}></Route>
            <Route path="/payment-link/:id" element={<PaymentLink />}></Route>
            <Route path="/payment-link/subscriptions/:id" element={<SubscriptionPage />}></Route>
            <Route path="/process-payment" element={<MpgsPage />}></Route>
          </Routes>
        </ThemeProvider>
      </BrowserRouter>
    </FlagsmithProvider>
  );
}

export default App;
