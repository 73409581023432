import { ReactComponent as ErrorCardIconIcon } from "./error-card.svg";

const ErrorCardIcon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
        title?: string | undefined;
    }
> = (props) => <ErrorCardIconIcon {...props} />;

export default ErrorCardIcon;

