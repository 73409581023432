import { ReactComponent as MasterCardSavedIcon } from "./mastercard-saved.svg";

const MasterCardSaved: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
        title?: string | undefined;
    }
> = (props) => <MasterCardSavedIcon {...props} />;

export default MasterCardSaved;

