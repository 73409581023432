import { Typography } from "kudi-component-library";
import styled, { DefaultTheme, css } from "styled-components";

interface FormInputProps {
  isError?: boolean;
}

interface ExtendedProps {
  textAlign?: "left" | "center" | "right" | "justify";
  mt?: string;
  width?: string;
}

export const AppBody = styled.div`
  background: radial-gradient(
    33.59% 33.59% at 50% 113%,
    rgba(220, 120, 102, 0.8) 0%,
    #000 100%
  );
  width: 100%;
  height: 100vh;
`;

export const AppHeader = styled.div`
  color: ${({ theme }) => theme.nColors["n-dark"]} !important;
  font-weight: 700 !important;
  font-size: 22px !important;
  line-height: 24px !important;
  text-align: center;
  margin-bottom: 10px;
`;

export const AppHeaderText = styled(Typography)<ExtendedProps>`
  color: ${({ theme }) => theme.nColors["n-dark"]} !important;
  font-weight: 700 !important;
  font-size: ${({ fontSize }) => fontSize || "20px"};
  line-height: 22px !important;
  text-align: ${({ textAlign }) => textAlign || "center"};
  margin-top: ${({ mt }) => mt || "0"};
`;

export const InfoText = styled(Typography)<ExtendedProps>`
  color: ${({ theme, color }) => color || theme.nColors["n-grey6"]};
  font-size: ${({ fontSize }) => fontSize || "14px"};
  font-style: normal;
  font-weight: ${({ fontWeight }) => fontWeight || "400"};
  text-align: ${({ textAlign }) => textAlign || "left"};
  margin-top: ${({ mt }) => mt || "0"};
  line-height: 24px;
`;

export const CardNumberInputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 2px;
`;

export const CardInputLogo = styled.div`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
`;

export const CardNumberInput = styled.input<FormInputProps>`
  width: 100%;
  height: 42px;
  padding-left: 10px;
  outline: none;
  border: ${({ isError, theme }) =>
    isError
      ? `1px solid ${theme.nColors["n-red"]}`
      : `1px solid ${theme.nColors["n-grey1"]}`};
  border-radius: 5px;
  margin-top: 5px;
`;

export const FormInput = styled.input<FormInputProps>`
  width: 100%;
  height: 42px;
  padding-left: 10px;
  outline: none;
  border: ${({ isError, theme }) =>
    isError
      ? `1px solid ${theme.nColors["n-red"]}`
      : `1px solid ${theme.nColors["n-grey1"]}`};
  border-radius: 5px;
  margin-top: 5px;
`;

export const SpacerLine = styled.div`
  border-bottom: ${({ theme }) => `1px solid ${theme.nColors["n-grey1"]}`};
  margin: 20px 0px;
`;

export const PaymentMethodBox = styled.div`
  border: ${({ theme }) => `1px solid ${theme.nColors["n-grey1"]}`};
  border-radius: 8px;
  background-color: ${({ theme }) => theme.nColors["n-grey0"]} !important;
  padding: 15px;
  margin: 25px 0px;
  cursor: pointer;
  width: 100%;
`;

export const PaymentBoxHeader = styled(Typography)`
  color: ${({ theme }) => theme.nColors["n-dark"]} !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 24px !important;
`;

export const ButtonText = styled(Typography)`
  color: ${({ theme }) => theme.nColors["n-dark"]} !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 22px !important;
  text-align: center;
`;

export const ErrorText = styled(Typography)`
  color: ${({ theme }) => theme.nColors["n-red"]} !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 18px !important;
  text-align: left;
`;

export const WhiteBox = styled.div<ExtendedProps>`
  border: ${({ theme }) => `1px solid ${theme.nColors["n-grey1"]}`};
  border-radius: 8px;
  background-color: ${({ theme }) => theme.nColors["n-light"]} !important;
  color: ${({ theme }) => theme.nColors["n-dark"]};
  font-size: 12px;
  font-weight: 500 !important;
  padding: 5px;
  cursor: pointer;
  width: ${({ width }) => width || "fit-content"} !important;
  display: flex;
  justify-content: center;
`;

const genaralItemStyles = ({
  theme,
  error,
  isFocus,
  disabled,
  dashed,
}: {
  theme: DefaultTheme;
  error?: string;
  isFocus?: boolean;
  disabled?: boolean;
  dashed?: boolean;
}) => css`
  height: 100%;
  width: 100%;
  background-color: ${error && theme.colors.secondary.danger2};

  border: ${`1px ${dashed ? "dashed" : "solid"} ${
    // eslint-disable-next-line no-nested-ternary
    error
      ? theme.colors.secondary.danger1
      : isFocus
      ? theme.nColors["n-grey2"]
      : theme.nColors["n-grey1"]
  }`};

  border-radius: 4px;
  outline: none;

  ${disabled
    ? `
      background-color: ${theme.colors.primary.black6}};
      border: 1px solid ${theme.colors.primary.black6};
      color: ${theme.colors.primary.black3};
      cursor: not-allowed
    `
    : `:disabled {
      background-color: ${theme.colors.primary.black6};
      border: 1px solid ${theme.colors.primary.black6};
      color: ${theme.colors.primary.black3};
      cursor: not-allowed;
    }`}
`;

export const InputWrapper = styled.div<{ disabled?: boolean; width?: string }>`
  display: flex;
  flex-direction: column;
  border: none;
  border-radius: 4px;
  width: ${({ width }) => width || "420px"};
  position: relative;
  color: ${({ theme }) => theme.nColors["n-dark"]};

  .flag-dropdown {
    background-color: transparent !important;
    border: none !important;
  }

  .react-tel-input .selected-flag:hover,
  .react-tel-input .selected-flag:focus {
    background-color: transparent !important;
  }

  .react-tel-input .form-control {
    border: none;
    background-color: transparent !important;
    width: 100%;
    height: 46px;
  }

  .react-tel-input .flag-dropdown.open .selected-flag {
    background: transparent !important;
  }

  .react-tel-input .selected-flag {
    padding: 0;
  }

  .react-tel-input .selected-flag .flag {
    transform: scale(1.2);
  }

  .react-tel-input .selected-flag .arrow {
    border-top: 5px solid #bbbbbb;
  }

  .react-tel-input .selected-flag .arrow.up {
    border-bottom: 5px solid #bbbbbb;
  }
  input#phoneNumberInput::placeholder {
    letter-spacing: 0;
  }
`;

export const Label = styled.label<{ isForm?: boolean }>`
  color: ${({ theme }) => theme.colors.primary.black2};
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: #121212;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 5px;
  font-style: normal;
  font-weight: 400;
  ${({ isForm }) =>
    isForm &&
    `
        color: #121212;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        `}
`;

export const InputItemErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  /* margin-top: 12px; */
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  color: ${({ theme }) => theme.colors.secondary.danger1};

  span {
    display: block;
    margin-left: 4px;
  }
`;

// Input Item
export const InputItem = styled.input<{
  error?: string;
  isFocus?: boolean;
}>`
  ${({ theme, error, isFocus }) =>
    genaralItemStyles({ theme, error, isFocus })};

  font-size: 14px;
  line-height: 150%;
  height: 46px;
  padding: 0 12px;
  font-weight: 600;

  :active,
  :focus {
    border: 1px solid ${({ theme }) => theme.colors.primary.black2};
  }
`;

// Select Item
export const SelectItem = styled.div<{
  error?: string;
  open?: boolean;
  disabled?: boolean;
  isFocus?: boolean;
}>`
  ${({ theme, error, isFocus, disabled }) =>
    genaralItemStyles({ theme, error, isFocus, disabled })};

  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  height: 46px;
  padding: 0 24px 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  display: flex;

  span {
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
  }

  svg {
    transform: ${({ open }) => (open ? "rotate(0deg)" : "rotate(180deg)")};
  }
`;

export const SelectOptionsWrapper = styled.div<{
  open?: boolean;
  isFocus?: boolean;
}>`
  background-color: ${({ theme }) => theme.colors.primary.black7};
  border-radius: 4px;
  position: absolute;
  top: 90px;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: fit-content;
  padding: 11px 0;
  z-index: 5;

  ${({ open }) =>
    !open
      ? `
    height: 0;
    width: 0;
    outline: none;
    background-color: transparent;
    opacity: 0;
    padding: 0;
    margin: 0;
  `
      : ""};
`;

export const SelectOption = styled.div<{ active?: boolean }>`
  border-radius: 4px;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;
  padding: 5px 15px;
  margin-bottom: 6px;
  cursor: pointer;
  background-color: ${({ theme, active }) =>
    active ? theme.colors.primary.black6 : "transparent"};
`;

export const DownShiftOptionsWrapper = styled.ul<{
  open?: boolean;
  isFocus?: boolean;
}>`
  background-color: ${({ theme }) => theme.colors.primary.black7};
  border-radius: 4px;
  position: absolute;
  top: 70px;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: fit-content;
  padding: 11px 0;
  z-index: 5;
  list-style-type: none;
  max-height: 200px;
  overflow-y: auto;

  ${({ open }) =>
    !open
      ? `
      height: 0;
      width: 0;
      outline: none;
      background-color: transparent;
      opacity: 0;
      padding: 0;
      margin: 0;
    `
      : ""};
`;

export const DownShiftOption = styled.li<{ active?: boolean }>`
  border-radius: 4px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;
  padding: 10px 15px;
  margin-bottom: 6px;
  cursor: pointer;
  color: ${({ theme }) => theme.nColors["n-grey6"]};
  background-color: ${({ theme, active }) =>
    active ? theme.colors.primary.black6 : "transparent"};
`;

export const DownShiftItem = styled.button<{
  error?: string;
  open?: boolean;
  disabled?: boolean;
  isFocus?: boolean;
}>`
  ${({ theme, error, isFocus, disabled }) =>
    genaralItemStyles({ theme, error, isFocus, disabled })};

  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  height: 46px;
  padding: 0 24px 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  display: flex;
  background-color: #fff;

  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  svg {
    transform: ${({ open }) => (open ? "rotate(180deg)" : "rotate(0deg)")};
  }
`;
