import { ReactComponent as TransferActiveIconIcon } from "./transfer-active.svg";

const TransferActiveIcon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
        title?: string | undefined;
    }
> = (props) => <TransferActiveIconIcon {...props} />;

export default TransferActiveIcon;

