import { ReactComponent as FooterLogoDarkIconIcon } from "./footer-logo-dark.svg";

const FooterLogoDark: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
        title?: string | undefined;
    }
> = (props) => <FooterLogoDarkIconIcon {...props} />;

export default FooterLogoDark;

