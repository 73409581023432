import { ReactComponent as AtmCardActiveIconIcon } from "./atm-card-active.svg";

const AtmCardActiveIcon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
        title?: string | undefined;
    }
> = (props) => <AtmCardActiveIconIcon {...props} />;

export default AtmCardActiveIcon;

