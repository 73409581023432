import { ReactComponent as AppStoreIconIcon } from "./app-store.svg";

const AppStoreIcon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
        title?: string | undefined;
    }
> = (props) => <AppStoreIconIcon {...props} />;

export default AppStoreIcon;

