import { Flex, Button } from "rebass";
import Modal from "./modal";
import { AppHeaderText, ButtonText, InfoText } from "../styles";
import InfoIcon from "../../assets/info-icon";
import CloseModalNew from "../../assets/closemodal-new";

export default function ConfirmModal({
  showModal,
  handleClose,
  confirm,
  description = "By closing this checkout, you are confirming to close this checkout and cancel the transaction.",
}: {
  showModal: boolean;
  handleClose: () => void;
  confirm: () => void;
  description?: string;
}) {
  return (
    <Modal showModal={showModal} onClose={handleClose}>
      <Flex onClick={handleClose} justifyContent="flex-end" mb="10px">
        <CloseModalNew style={{ cursor: "pointer" }} />
      </Flex>
      <Flex
        pb="50px"
        bg="#FFFFFF"
        height="100%"
        style={{ borderRadius: "16px" }}
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <InfoIcon />
        <AppHeaderText mt="10px">Are you sure?</AppHeaderText>

        <InfoText mt="30px" fontSize="14px" textAlign="center" color="n-grey4">
          {description}
        </InfoText>

        <Flex
          style={{ gap: "16px" }}
          width="100%"
          className="cursor-pointer"
          alignItems="center"
          mt="30px"
          justifyContent="space-between"
        >
          <Button
            onClick={confirm}
            textAlign="center"
            bg="#FFCC00"
            color="#121212"
            variant="large"
            width="100%"
            height="50px"
            className="cursor-pointer"
            style={{ borderRadius: "8px" }}
          >
            <ButtonText style={{ whiteSpace: "nowrap" }}>Confirm</ButtonText>
          </Button>
          <Button
            onClick={handleClose}
            textAlign="center"
            bg="#FFFFFF"
            color="#121212"
            variant="large"
            width="100%"
            height="50px"
            className="cursor-pointer"
            style={{ border: "1px solid #E6E6E6", borderRadius: "8px" }}
          >
            <ButtonText>Cancel</ButtonText>
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
}
