import { ReactComponent as CvvHelpIcon } from "./cvv-help.svg";

const CvvHelp: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
        title?: string | undefined;
    }
> = (props) => <CvvHelpIcon {...props} />;

export default CvvHelp;

