import { ReactComponent as AtmCardInActiveIconIcon } from "./atm-card-inactive.svg";

const AtmCardInActiveIcon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
        title?: string | undefined;
    }
> = (props) => <AtmCardInActiveIconIcon {...props} />;

export default AtmCardInActiveIcon;

