import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  authenticatePhone,
  cancelPaymentLinkSubscription,
  checkoutOtp,
  confirmBankTransfer,
  createCheckoutLink,
  createOrder,
  fetchPaymentLink,
  fetchSubscriptionDetails,
  getKtaDetails,
  getOrderDetails,
  payWithCard,
  saveUserCard,
  fetchBanks,
  sendUssdCode,
} from './action';

export interface Orderdetails {
  code: string;
  description: string;
  data: {
    order: {
      orderReference: string;
      orderId: string;
      customerId: string;
      customerEmail: string;
      accountId: string;
      callbackUrl: string;
      amount: number;
      currency: string;
      businessName: string | null;
      businessEmail: string | null;
      businessLogo: string | null;
      fixedCharge: string;
      merchantPaysFees: boolean;
      orderAmount: string;
    };
    allowedPaymentModes: [];
    hasSavedCards: boolean;
    base64EncodedRsaPublicKey: string | null;
  };
}

export interface IOrderDetails {
  code: string;
  description: string;
  data: {
    merchantLogo: string | null;
    merchantName?: string;
    base64EncodedRsaPublicKey: string;
    order: {
      callbackUrl?: string;
      orderReference: string;
      orderId: string;
      customerId: string;
      customerEmail: string;
      amount: number;
      currency: string;
      orderAmount: string;
    };
  };
}
export interface IOrderResponse {
  code: string;
  description: string;
  data: {
    status: boolean;
    message: string;
    callbackUrl?: string;
    responseCode?: string;
    transactionId?: string;
    secureAuthenticationData?: {
      jwt?: string;
      md?: string;
      acsUrl?: string;
      termUrl?: string;
      authPage?: string;
    };
    order?: {
      callbackUrl: string | '';
      orderReference: string | '';
      orderId: string | '';
      customerId: string | '';
      customerEmail: string | '';
      amount: number | 0;
      currency: string | '';
      accountId: string | '';
      businessName: string | null;
      businessEmail: string | null;
      businessLogo: string | null;
      orderAmount: string | '';
    };
  };
}
export interface KtaOrderResponse {
  code: string;
  description: string;
  data: {
    accountNumber: string;
    accountName: string;
    bankName: string;
  };
}

export interface PhoneAuthResponse {
  code: string;
  description: string;
  data: {
    success: boolean;
    message: string;
  };
}
export interface PaymentLinkResponse {
  code: string;
  description: string;
  data: {
    paymentReference: string;
    merchantId: string;
    title: string;
    description: string;
    frequency: string;
    linkType: string;
    imageUrl: string;
    amount: string;
    currency: string;
    numberOfPayments: number;
    totalSales: string;
    redirectUrl: string;
    status: string;
    customFields: string[];
    endDate: string;
    paymentLinkUrl: string;
    isCustomerSetPrice: boolean;
    createdAt: string;
    isTippingEnabled: boolean;
    customerSetPriceEnabled: boolean;
    isCustomerSetPriceEnabled: boolean;
    recurring: boolean;
    merchantDetails: {
      firstName: string;
      lastName: string;
      businessName: string;
      phoneNumber: string;
      email: string;
    };
  };
}
export interface SubscriptionDetailsResponse {
  code: string;
  description: string;
  data: {
    subscriptionId: string;
    merchantId: string;
    paymentLinkTitle: string;
    customerEmail: string;
    customerName: string | null;
    status: string;
    cardUsed: string;
    expiresIn: string;
    merchantDetails: {
      firstName: string;
      lastName: string;
      businessName: string;
      phoneNumber: string;
      email: string;
    };
  };
}
export interface CancelPaymentLinkSubscriptionResponse {
  code: string;
  description: string;
  data: string;
}

export interface IFetchBankResponse {
  code: string;
  description: string;
  data: {
    ussdCode: [
      {
        ussdBankId: string;
        bankName: string;
        bankCode: string;
        processor: string;
        processorCodeName: string;
        ussdCodeFormat: string;
        enabled: boolean;
        promptMessage: string;
      }
    ];
    success: boolean;
    message: string;
  };
}

export interface IUssdCodeResponse {
  code: string;
  description: string;
  data: {
    ussdCode: string;
    success: boolean;
    message: string;
  };
}

export interface InitialState {
  isLoading: boolean;
  inProgress: boolean;
  confirmingPayment: boolean;
  orderDetails: Orderdetails | null;
  createOrderResponse: IOrderResponse | null;
  otpResponse: IOrderResponse | null;
  ktaResponse: KtaOrderResponse | null;
  confirmResponse: IOrderResponse | null;
  phoneAuthResponse: PhoneAuthResponse | null;
  saveCardResponse: PhoneAuthResponse | null;
  paymentLinkResponse: PaymentLinkResponse | null;
  subscriptionDetailsResponse: SubscriptionDetailsResponse | null;
  cardPaymentResponse: PhoneAuthResponse | null;
  createCheckoutLinkResponse: any | null;
  cancelPaymentLinkSubscriptionResponse: CancelPaymentLinkSubscriptionResponse | null;
  fetchBankResponse: IFetchBankResponse | null;
  ussdCodeResponse: IUssdCodeResponse | null;
}

const initialState: InitialState = {
  isLoading: true,
  inProgress: false,
  confirmingPayment: false,
  orderDetails: null,
  createOrderResponse: null,
  otpResponse: null,
  ktaResponse: null,
  confirmResponse: null,
  phoneAuthResponse: null,
  saveCardResponse: null,
  paymentLinkResponse: null,
  cardPaymentResponse: null,
  createCheckoutLinkResponse: null,
  subscriptionDetailsResponse: null,
  cancelPaymentLinkSubscriptionResponse: null,
  fetchBankResponse: null,
  ussdCodeResponse: null,
};

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOrderDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getOrderDetails.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getOrderDetails.fulfilled, (state, action: PayloadAction<Orderdetails>) => {
        state.isLoading = false;
        state.orderDetails = action.payload;
      });

    builder
      .addCase(createOrder.pending, (state) => {
        state.inProgress = true;
      })
      .addCase(createOrder.rejected, (state) => {
        state.inProgress = false;
      })
      .addCase(createOrder.fulfilled, (state, action: PayloadAction<IOrderResponse>) => {
        state.inProgress = false;
        state.createOrderResponse = action.payload;
      });
    builder
      .addCase(checkoutOtp.pending, (state) => {
        state.confirmingPayment = true;
      })
      .addCase(checkoutOtp.rejected, (state) => {
        state.confirmingPayment = false;
      })
      .addCase(checkoutOtp.fulfilled, (state, action: PayloadAction<IOrderResponse>) => {
        state.confirmingPayment = false;
        state.otpResponse = action.payload;
      });

    builder
      .addCase(getKtaDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getKtaDetails.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getKtaDetails.fulfilled, (state, action: PayloadAction<KtaOrderResponse>) => {
        state.isLoading = false;
        state.ktaResponse = action.payload;
      });

    builder
      .addCase(confirmBankTransfer.pending, (state) => {
        state.confirmingPayment = true;
      })
      .addCase(confirmBankTransfer.rejected, (state) => {
        state.confirmingPayment = false;
      })
      .addCase(confirmBankTransfer.fulfilled, (state, action: PayloadAction<IOrderResponse>) => {
        state.confirmingPayment = false;
        state.confirmResponse = action.payload;
      });
    builder
      .addCase(authenticatePhone.pending, (state) => {
        state.inProgress = true;
      })
      .addCase(authenticatePhone.rejected, (state) => {
        state.inProgress = false;
      })
      .addCase(authenticatePhone.fulfilled, (state, action: PayloadAction<PhoneAuthResponse>) => {
        state.inProgress = false;
        state.phoneAuthResponse = action.payload;
      });
    builder
      .addCase(saveUserCard.pending, (state) => {
        state.confirmingPayment = true;
      })
      .addCase(saveUserCard.rejected, (state) => {
        state.confirmingPayment = false;
      })
      .addCase(saveUserCard.fulfilled, (state, action: PayloadAction<PhoneAuthResponse>) => {
        state.confirmingPayment = false;
        state.saveCardResponse = action.payload;
      });
    builder
      .addCase(payWithCard.pending, (state) => {
        state.inProgress = true;
      })
      .addCase(payWithCard.rejected, (state) => {
        state.inProgress = false;
      })
      .addCase(payWithCard.fulfilled, (state, action: PayloadAction<PhoneAuthResponse>) => {
        state.inProgress = false;
        state.cardPaymentResponse = action.payload;
      });
    builder
      .addCase(fetchPaymentLink.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchPaymentLink.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(fetchPaymentLink.fulfilled, (state, action: PayloadAction<PaymentLinkResponse>) => {
        state.isLoading = false;
        state.paymentLinkResponse = action.payload;
      });
    builder
      .addCase(createCheckoutLink.pending, (state) => {
        state.inProgress = true;
      })
      .addCase(createCheckoutLink.rejected, (state) => {
        state.inProgress = false;
      })
      .addCase(createCheckoutLink.fulfilled, (state, action: PayloadAction<PhoneAuthResponse>) => {
        state.inProgress = false;
        state.createCheckoutLinkResponse = action.payload;
      });

    builder
      .addCase(fetchSubscriptionDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchSubscriptionDetails.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(fetchSubscriptionDetails.fulfilled, (state, action: PayloadAction<SubscriptionDetailsResponse>) => {
        state.isLoading = false;
        state.subscriptionDetailsResponse = action.payload;
      });
    builder
      .addCase(cancelPaymentLinkSubscription.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(cancelPaymentLinkSubscription.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(
        cancelPaymentLinkSubscription.fulfilled,
        (state, action: PayloadAction<CancelPaymentLinkSubscriptionResponse>) => {
          state.isLoading = false;
          state.cancelPaymentLinkSubscriptionResponse = action.payload;
        }
      );

    builder
      .addCase(fetchBanks.pending, (state) => {
        state.inProgress = true;
      })
      .addCase(fetchBanks.rejected, (state) => {
        state.inProgress = false;
      })
      .addCase(fetchBanks.fulfilled, (state, action: PayloadAction<IFetchBankResponse>) => {
        state.inProgress = false;
        state.fetchBankResponse = action.payload;
      });

    builder
      .addCase(sendUssdCode.pending, (state) => {
        state.inProgress = true;
      })
      .addCase(sendUssdCode.rejected, (state) => {
        state.inProgress = false;
      })
      .addCase(sendUssdCode.fulfilled, (state, action: PayloadAction<IUssdCodeResponse>) => {
        state.inProgress = false;
        state.ussdCodeResponse = action.payload;
      });
  },
});

// Export the actions
// eslint-disable-next-line
export const {} = orderSlice.actions;

// Export the reducer
export default orderSlice.reducer;
